import React, { useEffect } from "react";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import {
  Params,
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import {
  ZvjsSummaryTableRequestComponent,
  ZvjsSummaryTableRequestHeader,
} from "../../../common/components/ZvjsSummaryTable";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSummaryTable,
} from "../../../common";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as KeyboardDoubleArrowLeftIcon } from "../../../assets/icons/Other/keyboard_double_arrow_left.svg";
import { useUITranslation } from "../../../store/context/translation-context";
import { LoaderError } from "../../../router/LoaderError";
import { defer } from "react-router-dom";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/CIS_Ciselnik";
import { withLoader } from "../../../hoc/withLoader";
import { paths as szoo_v1_paths } from "../../../api/types/szoo_v1";
import {
  getTranslationByLanguageId,
  isSubmitting,
} from "../../../utils/helpers";
import { getCiselnikJazyk } from "../../../locales/i18n";
import i18n from "i18next";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { useModal } from "../../../store/context/modal-context";
import { useSnackbar } from "../../../store/context/snackbar-context";
import { requestTemplates } from "../../../common/request/requestTemplates";
import {
  RequestCounter,
  UserCalcData,
  ZvjsQuestionnaireTemplate,
} from "../../../common/request/redux/model";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { ActionResult, ActionResultTypes } from "../../../router/ActionResult";
import { CreateZiadostRequestBody } from "../../../common/specs/swaggerTypes";
import { RequestSzooCode } from "../../../common/request/requestTemplates/requestTemplates";
import { getCounters } from "../../../utils/dataFetchers";
import { loadPrecalculatedData } from "../../../common/request/redux/actions";
import { RequestClientContentDetail } from "./index";

export async function RequestDetailActions({
  params,
  request,
}: {
  params: Params;
  request: Request;
}): Promise<ActionResult> {
  const body: { requestId: number | undefined } = await request.json();

  const { SZOO_Post } = await API_Clients();

  if (body.requestId !== undefined) {
    const result = await SZOO_Post("/api/Ziadosti/SpatVzatie", {
      body: {
        ziadostId: body.requestId,
      },
    });

    if (
      result.data?.data?.stavZiadostiId === 15 || // CisStavZiadosti "Späťvzatá"
      result.data?.data?.stavZiadostiId === 16 // CisStavZiadosti "Späťvzatá na spracovanie"
    ) {
      return { type: ActionResultTypes.SUCCESS };
    }
  }

  return { type: ActionResultTypes.ERROR };
}

export const getClientRequestContent = async (
  identifyNumber: string
): Promise<{
  counters: RequestCounter;
  precalculatedData: UserCalcData;
  requestTemplate: ZvjsQuestionnaireTemplate;
  requestHasuraBody: CreateZiadostRequestBody;
}> => {
  const { HASURA_Get } = await API_Clients();

  const responseHasura = await HASURA_Get(
    "/api/rest/v1/kiosk-ziadost-by-id/{id}",
    {
      params: {
        path: {
          id: identifyNumber,
        },
      },
    }
  );

  if (
    responseHasura.error ||
    !responseHasura.response.ok ||
    !responseHasura.data.kioskZiadost?.body
  ) {
    console.error(
      `Failed to fetch request hasura content response for request with identify number ${identifyNumber}`
    );
    throw new LoaderError();
  }

  const requestBody: CreateZiadostRequestBody = responseHasura.data.kioskZiadost
    .body as CreateZiadostRequestBody;

  const requestTemplate =
    requestTemplates[requestBody.typZiadosti as `${RequestSzooCode}`];

  if (requestTemplate === undefined || requestTemplate === null) {
    console.error(
      `Request with code ${requestBody.typZiadosti} not found in request templates`
    );
    throw new LoaderError();
  }

  const countersResponse = await getCounters(requestTemplate.counters ?? []);
  if (
    countersResponse.error ||
    !countersResponse.response.ok ||
    !countersResponse.data
  ) {
    console.error("Failed to fetch counters");
    throw new LoaderError();
  }
  const counters: RequestCounter = countersResponse.data;

  const precalculatedData = await loadPrecalculatedData(
    requestTemplate,
    counters
  );

  return {
    counters: counters,
    precalculatedData: precalculatedData,
    requestTemplate: requestTemplate,
    requestHasuraBody: requestBody,
  };
};

const LoadData = async (requestId?: string) => {
  if (requestId === undefined || isNaN(Number(requestId))) {
    // if requestID is not provided or not a number, display error page
    throw new LoaderError();
  }

  const { CIS_Post, EOO_Get, SZOO_Get } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisTypZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    CIS_Post("/api/CisStavZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    SZOO_Get("/api/Ziadosti/Get", {
      params: {
        query: {
          id: Number(requestId),
        },
      },
    }),
    EOO_Get("/api/Klient/DetailZakladneUdajeKlientaData", {
      params: {
        query: {
          Id: Number(localStorage.getItem("klientId")),
          UstavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      },
    }),
    SZOO_Get("/api/Ziadosti/GetOdpovedPreKlienta", {
      params: {
        query: {
          id: Number(requestId),
        },
      },
    }),
    CIS_Post("/api/CisOdpovedPreKlienta/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (
    data[0].error ||
    data[1].error ||
    data[2].error ||
    data[3].error ||
    data[4].error ||
    data[5].error
  ) {
    // if counters and request detail etc. were not fetched show error page
    throw new LoaderError();
  }

  const userPersonalData = {
    fullName: `${data[3].data.data?.meno} ${data[3].data.data?.priezvisko}`,
    birthDate: data[3].data.data?.datumNarodenia
      ? new Date(data[3].data.data.datumNarodenia).zvjsToString()
      : "",
  };

  const requestSzooDetailBody: szoo_v1_paths["/api/Ziadosti/Get"]["get"]["responses"]["200"]["content"]["application/json"] =
    data[2].data;

  if (
    requestSzooDetailBody.data === undefined ||
    requestSzooDetailBody.data.identifikacneCislo === undefined ||
    requestSzooDetailBody.data.identifikacneCislo === null
  ) {
    // if request detail was not found, display error page
    console.error(
      `Failed to fetch request szoo detail body for request with id ${requestId}`
    );
    throw new LoaderError();
  }

  const requestType = {
    ...data[0],
    requests: data[0].data.records?.filter(
      (request) => request.id === requestSzooDetailBody.data?.typZiadostiId
    ),
  };

  return {
    requestTypeCounter: requestType,
    requestStatesCounter: data[1],
    requestSzooDetail: requestSzooDetailBody,
    userPersonalData: userPersonalData,
    requestDetailResponseForClient:
      (data[4]
        .data as szoo_v1_paths["/api/Ziadosti/GetOdpovedPreKlienta"]["get"]["responses"]["200"]["content"]["application/json"]) ??
      [],
    requestResponseForClientCounter: data[5],
    clientRequestContent: await getClientRequestContent(
      requestSzooDetailBody.data.identifikacneCislo
    ),
  };
};

export const RequestDetailLoader = async ({
  request,
  params,
}: {
  request: Request;
  params: Params;
}) => {
  return defer({
    data: LoadData(params.requestID),
  });
};

export interface RequestDetailProps {
  data: Awaited<ReturnType<typeof LoadData>>;
}

const RequestDetail = (props: RequestDetailProps) => {
  const {
    requestTypeCounter,
    requestStatesCounter,
    requestSzooDetail,
    userPersonalData,
    requestDetailResponseForClient,
    requestResponseForClientCounter,
    clientRequestContent,
  } = props.data;
  const navigate = useNavigate();
  const { tui, tuiz, getFallbackJazyk } = useUITranslation();
  const { openModal, closeModal } = useModal();
  const submit = useSubmit();
  const navigation = useNavigation();
  const submitResult = useActionData() as ActionResult | undefined;
  const { openSnackbar } = useSnackbar();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (submitResult?.type === ActionResultTypes.ERROR) {
      openSnackbar(
        "Žiadosť sa nepodarilo zrušiť. Skúste neskôr.",
        ActionResultTypes.ERROR
      );
    }
    if (submitResult?.type === ActionResultTypes.SUCCESS) {
      openSnackbar("Žiadosť bola úspešne zrušená", ActionResultTypes.SUCCESS);
      navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}?tab=1`);
    }
  }, [navigate, openSnackbar, submitResult]);

  /**
   * @deprecated get request state color from counter once it is implemented
   */
  const getRequestStateColor = (requestStateId: number | undefined): string => {
    if (requestStateId !== undefined) {
      const requestState = getRequestStateNameFromCode(requestStateId);

      if (requestState !== undefined && requestState !== null) {
        if (requestState.includes("Zaevidovaná")) {
          return ZVJS_COLORS.BLUE;
        }
        if (requestState.includes("Schválená")) {
          return ZVJS_COLORS.GREEN;
        }
        if (requestState.includes("Zamietnutá")) {
          return ZVJS_COLORS.RED;
        }
        if (requestState.includes("Späťvzatá")) {
          return ZVJS_COLORS.PURPLE;
        }
      }
    }

    return ZVJS_COLORS.BLUE;
  };

  const getRequestStateNameFromCode = (code: number | undefined) => {
    for (const state of requestStatesCounter.data?.records ?? []) {
      if (state.kod === code?.toString()) {
        return state.nazov;
      }
    }
    return undefined;
  };

  const onConfirmRequestCancellation = () => {
    closeModal();
    submit(
      JSON.parse(JSON.stringify({ requestId: requestSzooDetail.data?.id })),
      {
        method: "POST",
        encType: "application/json",
      }
    );
  };

  const getRequestClientResponseTextFromCode = (code: number | undefined) => {
    // TODO add transnlations once available in counter
    for (const responseForClientItem of requestResponseForClientCounter.data
      ?.records ?? []) {
      if (responseForClientItem.kod === code?.toString()) {
        return responseForClientItem.text ?? "";
      }
    }
    console.error(
      `Request response with code ${code} not found in request response counter`
    );
    return "";
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid item mb={3}>
        <Stack direction="row" spacing={2} mb={3} alignItems="flex-start">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant="h1" mb={3}>
            {capitalize(tui("Detail žiadosti"))}
          </Typography>
        </Stack>
        <Typography variant="h3">
          {requestSzooDetail.data?.typZiadostiId
            ? getTranslationByLanguageId(
                requestTypeCounter.data?.records ?? [],
                getCiselnikJazyk(i18n.language),
                getFallbackJazyk(),
                requestSzooDetail.data?.typZiadostiId,
                "nazov"
              )
            : ""}
        </Typography>
      </Grid>
      <Stack spacing={5}>
        <Stack spacing={5}>
          <ZvjsSummaryTable>
            <ZvjsSummaryTableRequestHeader
              label={tuiz("ziadostiVseobecne.osobneUdaje")}
            />
            <ZvjsSummaryTableRequestComponent
              label={tuiz("ziadostiVseobecne.menoPriezvisko")}
              value={userPersonalData.fullName as string}
              borderBottom={true}
              textAlign={"left"}
            />
            <ZvjsSummaryTableRequestComponent
              label={tuiz("ziadostiVseobecne.datumNarodenia")}
              value={userPersonalData.birthDate as string}
              borderBottom={true}
              textAlign={"left"}
            />
            <ZvjsSummaryTableRequestHeader
              // TODO add translation
              label={tuiz("Odpovede pre klienta")}
            />
            <ZvjsSummaryTableRequestComponent
              label={"Stav žiadosti"}
              value={
                <div
                  style={{
                    backgroundColor: getRequestStateColor(
                      requestSzooDetail.data?.stavZiadostiId
                    ),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    width: "fit-content",
                    borderRadius: "0.2rem",
                  }}
                >
                  <Typography
                    fontSize={"20"}
                    color={ZVJS_COLORS.WHITE}
                    textAlign={"center"}
                    paddingLeft={1}
                    paddingRight={1}
                  >
                    {getRequestStateNameFromCode(
                      requestSzooDetail.data?.stavZiadostiId
                    ) ?? ""}
                  </Typography>
                </div>
              }
              borderBottom={true}
              textAlign={"left"}
            />
            {requestDetailResponseForClient.records?.map(
              (requestResponse, index) => {
                return (
                  <ZvjsSummaryTableRequestComponent
                    key={index}
                    label={new Date(requestResponse.datum ?? "").zvjsToString()}
                    value={requestResponse.odpovedText ?? ""}
                    textAlign={"left"}
                    borderBottom={true}
                    longText
                  />
                );
              }
            )}
            <ZvjsSummaryTableRequestHeader
              label={tuiz("ziadostiVseobecne.udajeZiadosti")}
            />
            <ZvjsSummaryTableRequestComponent
              label={"Žiadosť"}
              value={
                requestSzooDetail.data?.typZiadostiId
                  ? getTranslationByLanguageId(
                      requestTypeCounter.data?.records ?? [],
                      getCiselnikJazyk(i18n.language),
                      getFallbackJazyk(),
                      requestSzooDetail.data?.typZiadostiId,
                      "nazov"
                    )
                  : ""
              }
              textAlign={"left"}
            />
            <RequestClientContentDetail
              clientRequestContent={clientRequestContent}
            />
          </ZvjsSummaryTable>
        </Stack>
        {requestSzooDetail.data?.jeMozneSpatvzatie && (
          <Stack direction="row" spacing={2}>
            <ZvjsButton
              zvjsVariant={"secondaryAction"}
              text={capitalize(tui("Zrušiť žiadosť"))}
              startIcon={<KeyboardDoubleArrowLeftIcon style={{ height: 24 }} />}
              disabled={isSubmitting(navigation)}
              onClick={() =>
                openModal(
                  <CancelRequestModalBody
                    onCancel={closeModal}
                    onConfirm={onConfirmRequestCancellation}
                  />
                )
              }
            />
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default withLoader(RequestDetail);

interface CancelRequestModalBodyProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const CancelRequestModalBody: React.FC<CancelRequestModalBodyProps> = (
  props: CancelRequestModalBodyProps
) => {
  const { onCancel, onConfirm } = props;

  return (
    <Paper
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "1rem",
        borderRadius: "1rem",
        boxShadow: 24,
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <Stack spacing={1}>
        <Box
          style={{
            backgroundColor: "rgba(0,112,60,0.3)",
            minWidth: "30rem",
            borderRadius: "0.5rem",
            border: `0.1rem solid ${ZVJS_COLORS.GREEN}`,
            padding: "0.5rem",
          }}
        >
          <Stack direction="row" spacing={2} justifyContent={"center"}>
            {/*<Hint fill={ZVJS_COLORS.GREEN} />*/}
            <Typography
              variant={"h4"}
              fontWeight={700}
              color={ZVJS_COLORS.GREEN}
            >
              {/*TODO add translation*/}
              {capitalize("Zrušiť žiadosť")}
            </Typography>
          </Stack>
        </Box>
        <Typography
          id="transition-modal-description"
          sx={{ mt: 2 }}
          paddingLeft={1}
          paddingRight={1}
        >
          {/*TODO add translation*/}
          {capitalize("Naozaj si želáte zrušiť žiadosť? ")}
        </Typography>
        <Stack
          sx={{ display: "flex", justifyContent: "center" }}
          spacing={1}
          direction={"row"}
        >
          <ZvjsButton
            zvjsVariant={"secondaryAction"}
            onClick={onConfirm}
            // TODO add translation
            text={"Áno"}
            sx={{ height: "3rem" }}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            onClick={onCancel}
            // TODO add translation
            text={"Nie"}
            sx={{ height: "3rem" }}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};
