import React, { useContext, useEffect, useSyncExternalStore } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/context/auth-context";
import { ZvjsIframe } from "../../common";
import {
  JsonApiCallWithContentHeaders,
  targetApi,
} from "../../utils/targetApi";
import { defer } from "react-router-dom";
import { withLoaderHolup } from "../../hoc/withLoaderHolup";
import { Module } from "../../utils/module";
import { LoaderError } from "../../router/LoaderError";
import { ActionResultTypes } from "../../router/ActionResult";

const LoadData = async () => {
  try {
    const data = await fetch(
      await targetApi(`/${Module.INTERNAL}/api/v1/encrypt`),
      {
        headers: await JsonApiCallWithContentHeaders(),
        // TODO: retrieve klientId and ustav from localStorage
        body: JSON.stringify({ klientId: "0000002", ustav: "Ilava" }),
        method: "POST",
      }
    );

    if (!data.ok) {
      throw new LoaderError();
    }

    const json = await data.json();
    return {
      data: json.data,
    };
  } catch (e) {
    // example how to throw exception if loader
    console.error(e, "Loader: ", "Something went wrong");
    return {
      type: ActionResultTypes.ERROR,
      message: "Something went wrong",
    };
  }
  // example how to throw exception if loader
  //   throw new LoaderError()
};

export const HolupLoader = async () => {
  return defer({
    data: LoadData(),
  });
};

interface HolupProps {
  data?: any | undefined;
}

const Holup = (data: HolupProps) => {
  const navigate = useNavigate();
  const { onLogout } = useContext(AuthContext);

  useEffect(() => {
    const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
      if (ev.data.type === "holup-kiosk" && ev.data.message === "logout") {
        onLogout();
      }
    };

    window.addEventListener("message", handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ZvjsIframe
        header={false}
        iframeTitle={"holup"}
        id={"iframe"}
        url={`https://app-test.sancananavrat.sk/?data=${data.data.data}`}
        onClose={() => navigate("/auth")}
      />
    </>
  );
};

export default withLoaderHolup(Holup);
