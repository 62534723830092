import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsTabs } from "../../../common";
import RequestCategories from "./RequestCategories";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import UserRequestsList from "./UserRequestsList";
import { useUITranslation } from "../../../store/context/translation-context";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/CIS_Ciselnik";
import { LoaderError } from "../../../router/LoaderError";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { API_Clients } from "../../../store/context/dataApi/Data";

const LoadData = async () => {
  const { CIS_Post, SZOO_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisKategoriaZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    CIS_Post("/api/CisStavZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    SZOO_Post("/api/interfaces/Szoo/ListZoznamZiadostiKlienta", {
      body: { klientId: { eq: localStorage.getItem("klientObjectId") ?? "" } },
    }),
    // fetch all request types,
    // not only actual in purpose to display the name of some legacy requests in user requests list page
    CIS_Post("/api/CisTypZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (data[0].error || data[1].error || data[2].error || data[3].error) {
    // if counter was not fetched show error page
    throw new LoaderError();
  }

  const userRequests = data[2];

  // TODO replace with sorting via BE call
  userRequests.data.sort(
    (r1, r2) =>
      new Date(r2.datumPodaniaZiadosti ?? "").getTime() -
      new Date(r1.datumPodaniaZiadosti ?? "").getTime()
  );

  return {
    requestCategories: data[0],
    requestStates: data[1],
    initialUserRequests: userRequests,
    requestTypes: data[3],
  };
};

export const RequestsLoader = async () => {
  return defer({
    data: LoadData(),
  });
};

export interface RequestsLoadedDataType {
  data: Awaited<ReturnType<typeof LoadData>>;
}

const Requests = (props: RequestsLoadedDataType) => {
  const {
    requestStates,
    requestCategories,
    initialUserRequests,
    requestTypes,
  } = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const [mainLabel, setMainLabel] = useState("dashboard.kategorieZiadosti");

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const onChangeHandler = (value: number) => {
    switch (value) {
      case 0:
        setMainLabel("dashboard.kategorieZiadosti");
        break;
      case 1:
        // TODO replace with tui value
        setMainLabel("moje podané žiadosti");
        break;
    }
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui(mainLabel))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabs
            onChange={onChangeHandler}
            data={[
              {
                label: tui("dashboard.kategorieZiadosti"),
                element: (
                  <RequestCategories requestCategories={requestCategories} />
                ),
              },
              {
                // TODO replace with tui value
                label: capitalize(tui("moje podané žiadosti")),
                element: (
                  <UserRequestsList
                    requestStates={requestStates}
                    initialUserRequests={initialUserRequests}
                    requestTypes={requestTypes}
                  />
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(Requests);
