import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ZVJS_COLORS } from "../theme/zvjs_theme";
import { getUuid } from "../utils/helpers";
import TableCell from "@mui/material/TableCell";
import AppActivityGuard from "../utils/AppActivityGuard";
import { ZvjsPullToRefresh } from "../common";
import { API_Clients } from "../store/context/dataApi/Data";

const SynchronizationStatusPage = () => {
  const theme = useTheme();

  const [nazovUstavu, setNazovUstavu] = useState("Ústav");
  const [nazvyObjektov, setNazvyObjektov] = useState("Objekt");
  const [nazvySektorov, setNazvySektorov] = useState("Sektor");
  const [nazvyBlokov, setNazvyBlokov] = useState("Blok");
  const [nazvyUbytovacichPriestorov, setNazvyUbytovacichPriestorov] =
    useState("Ubytovaci priestor");
  const [menaKlientov, setMenaKlientov] = useState("Mena klientov");

  const [syncStatusText, setSyncStatusText] = useState("-");

  // TODO
  const syncIsRunning = false;

  const isCellSelected = () => {
    // TODO
    return true;
  };

  const getDeviceUuid = () => {
    let tabletUUID = localStorage.getItem("tabletUUID");
    if (!tabletUUID) {
      tabletUUID = getUuid();
      localStorage.setItem("tabletUUID", tabletUUID);
    }

    return tabletUUID;
  };

  // TODO
  const showStartSynchroAgainButton = true;

  const invokeSynchronization = async () => {
    const { CIS_Post, EOO_Post, ZVJS_MP_Post } = await API_Clients();

    const tabletData = await ZVJS_MP_Post("/administracia-tabletu", {
      body: {
        datumPripojenia: new Date().toISOString(),
        kioskTabletId: getDeviceUuid(),
      },
    });

    console.debug("Tablet data:");
    console.debug(tabletData.data);

    if (!tabletData.data?.ustavId) {
      const errorText =
        "Pre tablet ešte nebol definovaný ústav v Manažmente procesov.";
      setSyncStatusText(errorText);
      console.error(errorText);
      return;
    }

    if (!tabletData.data?.ubytovaciaJednotkaList) {
      const errorText =
        "Pre tablet ešte nebola definovaná ubytovacia jednotka/y v Manažmente procesov.";
      setSyncStatusText(errorText);
      console.error(errorText);
      return;
    }

    console.info(
      `Synchronizacia: ustavId[${
        tabletData.data.ustavId
      }], ubytovaciaJednotkaList[${tabletData.data.ubytovaciaJednotkaList.join(
        ","
      )}]`
    );

    const resultArray = tabletData.data.ubytovaciaJednotkaList
      ? await Promise.all(
          tabletData.data?.ubytovaciaJednotkaList?.map((ubytovaciaJednotka) =>
            EOO_Post("/api/Klient/List", {
              body: {
                filters: [
                  {
                    ustavID: tabletData.data.ustavId,
                    ubytovaciPriestorId: ubytovaciaJednotka,
                  },
                ],
              },
            })
          )
        )
      : [];

    const klientIds: number[] = resultArray
      .flat(1)
      .map((result) => result.data?.records)
      .flat(1)
      .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
      .filter((record) =>
        tabletData.data.ubytovaciaJednotkaList?.includes(
          record?.ubytovaciPriestorId ?? 99999
        )
      )
      .map((record) => record?.id)
      .filter((id): id is number => !!id);

    const cisUstav = await CIS_Post("/api/CisUstavZvjs/List", {
      body: {
        filters: [
          {
            aktualny: true,
            kodKontaktneUdajeUstavuId: tabletData.data.ustavId,
          },
        ],
      },
    });

    setNazovUstavu(
      cisUstav.data?.records
        ?.find((i) => i.kodKontaktneUdajeUstavuId === tabletData.data.ustavId)
        ?.skratka?.trim() ?? ""
    );

    setNazvyObjektov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .map((record) => record?.objektNazov)
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ")
    );

    setNazvySektorov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .map((record) => record?.sektorNazov)
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ") ?? ""
    );

    setNazvyBlokov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .map((record) => record?.blokNazov)
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ")
    );

    setNazvyUbytovacichPriestorov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .map((record) => record?.ubytovaciPriestorNazov)
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ")
    );

    setMenaKlientov(
      resultArray
        .flat(1)
        .map((result) => result.data?.records)
        .flat(1)
        .filter((record) => record?.ustavZvjsId === tabletData.data.ustavId)
        .filter((record) =>
          tabletData.data.ubytovaciaJednotkaList?.includes(
            record?.ubytovaciPriestorId ?? 99999
          )
        )
        .sort((k1, k2) =>
          (k1?.priezvisko ?? "").localeCompare(k2?.priezvisko ?? "")
        )
        .map(
          (record) =>
            `${record?.meno ?? ""} ${record?.druheMeno ?? ""} ${
              record?.priezvisko
            }`
        )
        .filter((nazov): nazov is string => !!nazov)
        .filter((value, index, array) => array.indexOf(value) === index)
        .join(", ")
    );

    navigator.serviceWorker.controller?.postMessage({
      type: "SYNCHRONIZE_DATA",
      klientIds: klientIds,
      ustavZvjsId: tabletData.data.ustavId,
    });
  };

  const handleMessage = (event: MessageEvent) => {
    if (event.data && event.data.type === "SYNCHRONIZATION_STATUS") {
      setSyncStatusText(event.data.message);
    }
  };

  const channel = new BroadcastChannel("synchronization_status");
  channel.onmessage = handleMessage;

  useEffect(() => {
    // start synchronization on a synchronization status page load
    invokeSynchronization();
  }, []);

  return (
    <AppActivityGuard>
      <ZvjsPullToRefresh>
        <Grid container bgcolor={ZVJS_COLORS.GREY}>
          <Grid item xs={12} mt={theme.spacing(3)}>
            <Grid container justifyContent={"center"}>
              <Grid item sx={{ maxWidth: "50%" }}>
                <Paper
                  elevation={1}
                  sx={{ padding: theme.spacing(1), textAlign: "center" }}
                >
                  <Typography variant={"h4"}>{"Administrácia"}</Typography>
                  <Typography variant={"body2"}>{getDeviceUuid()}</Typography>
                  <Divider
                    sx={{
                      marginTop: theme.spacing(1),
                      marginBottom: syncIsRunning ? 0 : theme.spacing(1),
                    }}
                  />
                  {syncIsRunning && (
                    <LinearProgress
                      sx={{ height: 2, marginBottom: theme.spacing(1) }}
                    />
                  )}
                  <Typography variant={"body1"}>{syncStatusText}</Typography>
                  <Table
                    size={"small"}
                    sx={{
                      marginRight: theme.spacing(3),
                      marginLeft: theme.spacing(3),
                      width: "calc(100% - 48px)",
                    }}
                  >
                    {isCellSelected() && (
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Ústav"}
                          </TableCell>
                          <TableCell align="right">{nazovUstavu}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Objekt"}
                          </TableCell>
                          <TableCell align="right">{nazvyObjektov}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Sektor"}
                          </TableCell>
                          <TableCell align="right">{nazvySektorov}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Blok"}
                          </TableCell>
                          <TableCell align="right">{nazvyBlokov}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Ubytovací priestor"}
                          </TableCell>
                          <TableCell align="right">
                            {nazvyUbytovacichPriestorov}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Klient"}
                          </TableCell>
                          <TableCell align="right">{menaKlientov}</TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  {showStartSynchroAgainButton && (
                    <button
                      onClick={() => {
                        invokeSynchronization();
                      }}
                      style={{
                        backgroundColor: ZVJS_COLORS.BLUE,
                        color: ZVJS_COLORS.WHITE,
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "5px",
                      }}
                    >
                      {"Spustiť aktualizáciu znova"}
                    </button>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ZvjsPullToRefresh>
    </AppActivityGuard>
  );
};

export default SynchronizationStatusPage;
