import { Button, Grid, Stack, Typography } from "@mui/material";
import { useUITranslation } from "../../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../common";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import {
  RowData,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async () => {
  const { ER_Post } = await API_Clients();

  const data = await Promise.all([
    ER_Post("/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi", {
      body: {
        objectKlientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
    ER_Post("/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta", {
      body: {
        objectKlientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
    ER_Post("/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta", {
      body: {
        objectKlientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
  ]);
  return {
    ineRozhodnutia: data[0],
    disciplinarneOdmeny: data[1],
    disciplinarneTresty: data[2],
  };
};

export const DisciplinarneTrestyAIneRozhodnutiaLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface DisciplinarneTrestyAIneRozhodnutiaProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const DisciplinarneTrestyAIneRozhodnutia = (
  props: DisciplinarneTrestyAIneRozhodnutiaProps
) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  // const mySubmitHandler = (
  //   dateFrom: Date,
  //   dateTo: Date,
  //   dropDownValue: string
  // ) => {
  //   console.log(dateFrom);
  //   console.log(dateTo);
  // };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              tui("osobneUdaje.kategorieOsobnychUdajov.disciplinarneTresty")
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={{
          label: capitalize(tui("osobneUdaje.disciplinarneTresty.tresty")),
          header: [
            {
              value: capitalize(
                tui("osobneUdaje.disciplinarneTresty.datumUlozenia")
              ),
              align: "left",
              width: "calc(100% / 5)",
            },
            {
              value: capitalize(tui("osobneUdaje.disciplinarneTresty.stav")),
              align: "left",
              width: "calc(100% / 5)",
            },
            {
              value: capitalize(tui("osobneUdaje.disciplinarneTresty.druh")),
              align: "left",
              width: "calc(100% / 5)",
            },
            {
              value: capitalize(
                tui("osobneUdaje.disciplinarneTresty.porusenaPovinnost")
              ),
              align: "left",
              width: "calc(100% / 5)",
            },
            {
              value: capitalize(tui("akcia")),
              align: "right",
              width: "calc(100% / 5)",
            },
          ],
          body:
            props.data.disciplinarneTresty.data?.map((disciplinarnyTrest) => {
              const rd: RowData = {
                row: [
                  {
                    // TODO: "datum ulozenia" - taky atribut nie je
                    value: new Date(
                      disciplinarnyTrest.datumUstnehoOznameniaKlientovi ??
                        new Date()
                    ).zvjsToString(),
                    align: "left",
                    width: "calc(100% / 5)",
                  },
                  {
                    value: disciplinarnyTrest.zrusene
                      ? tui("zrušený")
                      : tui("nezrušený"),
                    align: "left",
                    width: "calc(100% / 5)",
                  },
                  {
                    value:
                      disciplinarnyTrest.druhDisciplinarnehoTrestuNazov ?? "",
                    align: "left",
                    width: "calc(100% / 5)",
                  },
                  {
                    value: disciplinarnyTrest.porusenePovinnostiId ?? "",
                    align: "left",
                    width: "calc(100% / 5)",
                  },
                  {
                    value: (
                      <ZvjsButton
                        text={capitalize(
                          // tui("osobneUdaje.disciplinarneTresty.detailTrestu") TODO: Too long
                          tui("Detail")
                        )}
                        zvjsVariant="secondaryAction"
                        startIcon={
                          <DetailIcon
                            style={{ fill: "white", width: 28, height: 28 }}
                          />
                        }
                        onClick={() => {
                          navigate(
                            `DisciplinarySanction/${disciplinarnyTrest.erRozhDiscTrestId}`
                          );
                        }}
                      />
                    ),
                    align: "right",
                    width: "calc(100% / 5)",
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.disciplinarneTresty.napovedaTresty")}
      />

      <ZvjsTable
        data={{
          label: capitalize(tui("osobneUdaje.disciplinarneOdmeny.odmeny")),
          header: [
            {
              value: capitalize(
                tui("osobneUdaje.disciplinarneOdmeny.datumUlozenia")
              ),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("osobneUdaje.disciplinarneOdmeny.druh")),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("akcia")),
              align: "right",
              width: "calc(100% / 3)",
            },
          ],
          body:
            props.data.disciplinarneOdmeny.data?.map((disciplinarnaOdmena) => {
              const rd: RowData = {
                row: [
                  {
                    value: new Date(
                      disciplinarnaOdmena.datumUstnehoOznameniaKlientovi ??
                        new Date()
                    ).zvjsToString(),
                    align: "left",
                    width: "calc(100% / 3)",
                  },
                  {
                    value: disciplinarnaOdmena.odmenaDruhNazov ?? "",
                    align: "left",
                    width: "calc(100% / 3)",
                  },
                  {
                    value: (
                      <ZvjsButton
                        text={capitalize(
                          // tui(
                          //   "osobneUdaje.disciplinarneOdmeny.detailOdmeny" // TODO: "too long"
                          // )
                          tui("Detail")
                        )}
                        zvjsVariant="secondaryAction"
                        startIcon={
                          <DetailIcon
                            style={{ fill: "white", width: 28, height: 28 }}
                          />
                        }
                        onClick={() => {
                          navigate(
                            `DisciplinaryReward/${disciplinarnaOdmena.erRozhDiscOdmenaId}`
                          );
                        }}
                      />
                    ),
                    align: "right",
                    width: "calc(100% / 3)",
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.disciplinarneOdmeny.napovedaOdmeny")}
      />

      <ZvjsTable
        data={{
          label: capitalize(tui("osobneUdaje.ineRozhodnutia.ine")),
          header: [
            {
              value: capitalize(tui("osobneUdaje.ineRozhodnutia.datum")), // TODO: datum <-> druh
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(
                tui("osobneUdaje.ineRozhodnutia.datumPravoplatnosti")
              ),
              align: "left",
              width: "calc(100% / 3)",
            },
            {
              value: capitalize(tui("akcia")),
              align: "right",
              width: "calc(100% / 3)",
            },
          ],
          body:
            props.data.ineRozhodnutia.data?.map((ineRozhodnutie) => {
              const rd: RowData = {
                row: [
                  {
                    value: ineRozhodnutie.druhRozhodnutiaNazov ?? "",
                    align: "left",
                    width: "calc(100% / 3)",
                  },
                  {
                    value: new Date(
                      ineRozhodnutie.datumPravoplatnosti ?? new Date()
                    ).zvjsToString(),
                    align: "left",
                    width: "calc(100% / 3)",
                  },
                  {
                    value: (
                      <ZvjsButton
                        text={capitalize(
                          //tui("osobneUdaje.ineRozhodnutia.detailRozhodnutia") TODO: too long use just "Detail"
                          tui("Detail")
                        )}
                        zvjsVariant="secondaryAction"
                        startIcon={
                          <DetailIcon
                            style={{ fill: "white", width: 28, height: 28 }}
                          />
                        }
                        onClick={() => {
                          navigate(
                            `DisciplinaryOtherDecision/${ineRozhodnutie.erRozhodnutieId}`
                          );
                        }}
                      />
                    ),
                    align: "right",
                    width: "calc(100% / 3)",
                  },
                ],
              };
              return rd;
            }) ?? [],
        }}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.ineRozhodnutia.napovedaIneRozhodnutia")}
      />
    </Grid>
  );
};

export default withLoader(DisciplinarneTrestyAIneRozhodnutia);
