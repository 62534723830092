import { COMMON } from "./constants";
import {
  Calculate,
  NestedDropdownAnswerValueType,
  State,
  UserCalcData,
  ZvjsAnswerValueType,
  ZvjsConditionTypes,
  ZvjsCustomQuestion,
  ZvjsCustomQuestionTypes,
  ZvjsDropdownQuestion,
  ZvjsDropdownQuestion_New,
  ZvjsFormCondition,
  ZvjsGeneralItem,
  ZvjsItemTypes,
  ZvjsLabel,
  ZvjsLabelSingleValue,
  ZvjsNestedDropdown,
  ZvjsNestedDropdownOptions,
  ZvjsQuestion,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
  ZvjsRadioQuestion,
  ZvjsSection,
  ZvjsSingleCheckBoxQuestion,
  ZvjsTextQuestion,
  ZvjsValidationError,
} from "./model";
import { createSelector } from "@reduxjs/toolkit";
import {
  findItem,
  questionsIteratorWithLocAndParentTreeNestedDropdown,
  questionsIteratorWithLocation,
  questionsIteratorWithLocFromDisplayedSections,
} from "./slice";
import {
  getTranslationByLanguageCode,
  isEmptyArray,
  isEmptyString,
} from "../../../utils/helpers";
import { calculations } from "../requestPages/questionnaire/utils/index";
import allCustomQuestionSelectors from "../requestPages/questionnaire/items/questions/customQuestions/selectors";
import { ZvjsNestedDropdownViewOptions } from "../../components/ZvjsNestedDropDown";
import { customIsAnswered } from "../requestPages/questionnaire/items/questions/customQuestions/customAnswers";
import { allSummarySelectors } from "./selectors/summary";
import { useUITranslation } from "../../../store/context/translation-context";
import { CiselnikListResultType } from "../../../store/context/dataApi/CIS_Ciselnik";
import { getCiselnikJazyk } from "../../../locales/i18n";
import i18n from "i18next";
import { capitalize } from "@mui/material/utils";
import { ReusableCalculationType } from "../requestPages/questionnaire/utils/calculations/reusable";
import { CustomCalculationType } from "../requestPages/questionnaire/utils/calculations/custom";

export const selectSelf = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER];

// Listen to questionnaire only to avoid re-rendering of components which listen to questionnaire changes only
export const selectQuestionnaireTemplate = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER].questionnaire;

export const selectRequestCounterTemplate = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER].requestCounter;

// const selectAnswers = (state: { [x: string]: State }) =>
//   state[COMMON.ROOT_REDUCER].answers;

export const selectValidationErrors = (state: { [x: string]: State }) =>
  state[COMMON.ROOT_REDUCER].validationErrors;

const selectEditQuestion = (state: { [x: string]: State }) => {
  return state[COMMON.ROOT_REDUCER].editQuestion;
};

export const selectUserCalcData = (state: { [x: string]: State }) => {
  return state[COMMON.ROOT_REDUCER].userCalcData;
};

const getRootItems = createSelector(
  selectQuestionnaireTemplate,
  (state: ZvjsQuestionnaireTemplate) => {
    // do not display sections which do not have any items inside
    return state.items
      .filter(
        (item) => item.type !== ZvjsItemTypes.SECTION || item.items.length > 0
      )
      .map((item) => item.type);
  }
);

const getRequestDisplayData = () =>
  createSelector(
    selectRequestCounterTemplate,
    (state: CiselnikListResultType<"/api/CisTypZiadosti/List">["data"]) => {
      const { getFallbackJazyk } = useUITranslation();
      const requestCode = getRequestCodeFromCounter(state);

      return {
        title: getRequestTitle(state, requestCode, getFallbackJazyk()),
      };
    }
  );

const getQuestionToEdit = createSelector(
  selectEditQuestion,
  (id: string | undefined) => {
    return id;
  }
);

const getIntroductionDisplayData = () =>
  createSelector(selectSelf, (state: State) => {
    const { tuiz } = useUITranslation();
    const basicInfo: string[] = [];
    for (const info of state.questionnaire.basicInfo) {
      if (
        info.conditions === undefined ||
        isItemDisplayed([], state, info.conditions)
      ) {
        basicInfo.push(
          tuiz(info.text).replace(
            /###([A-Za-z0-9_]+)###/g,
            (match, placeholder) => {
              // placeholder holds value inside brackets #{0} => 0
              const newVal = state.userCalcData[
                placeholder as ReusableCalculationType | CustomCalculationType
              ] as UserCalcData | undefined;
              // if you have found new value use it in text string
              if (newVal !== undefined) {
                return newVal.toString();
              }
              // match holds original value
              return match;
            }
          )
        );
      }
    }
    return basicInfo;
  });

const getHasQuestionnaireAnyItems = createSelector(
  selectQuestionnaireTemplate,
  (state: ZvjsQuestionnaireTemplate) => {
    return state.items.length !== 0;
  }
);

const getSectionItems = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate) => {
      const foundItem = findItem(state, location) as ZvjsSection;
      // do not display sections which do not have any items inside
      return foundItem.items
        .filter(
          (item) => item.type !== ZvjsItemTypes.SECTION || item.items.length > 0
        )
        .map((item) => item.type);
    }
  );

const getSectionTitles = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate): string[] => {
      const titles: string[] = [];
      const searchedLocations: number[] = [];
      // if your section do not have title, do not retrieve titles of its parent and straight return empty array
      if ((findItem(state, location) as ZvjsSection).title === undefined) {
        return [];
      }
      for (const loc of location) {
        searchedLocations.push(Number(loc));
        titles.push(
          (findItem(state, searchedLocations) as ZvjsSection).title ?? ""
        );
      }
      return titles;
    }
  );

const getGeneralCustomQuestionDisplayData = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate) => {
      const question = findItem(state, location) as ZvjsCustomQuestion;
      return {
        id: question.id,
        title: question.title,
      };
    }
  );

const getDropdownQuestionDisplayData = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate) => {
      const { tuiz } = useUITranslation();
      const question = findItem(state, location) as ZvjsDropdownQuestion;
      return {
        id: question.id,
        title: question.title,
        options: question.options.map((option) => {
          return {
            value: option.id,
            text: tuiz(option.label),
          };
        }),
        hintText: question.hintText,
        isRequired: question.isRequired,
      };
    }
  );

const getDropdownQuestionNewDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsDropdownQuestion_New;
    const counter = state.counters[question.options.counterKey];
    const { getFallbackJazyk } = useUITranslation();
    return {
      id: question.id,
      title: question.title,
      options: question.options.optValues.map((option) => {
        return {
          value: option.id,
          text: capitalize(
            getTranslationByLanguageCode(
              counter?.records ?? [],
              getCiselnikJazyk(i18n.language),
              getFallbackJazyk(),
              option.id,
              question.options.textKey
            )
          ),
        };
      }),
      hintText: question.hintText,
      isRequired: question.isRequired,
    };
  });

/***
 * returns array of booleans which holds information which dropdown options should be displayed
 * @param location of current question
 */
const getDropdownOptionsDisplayedArray = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsDropdownQuestion;
    const displayedArray: boolean[] = [];
    for (const opt of question.options) {
      if (opt.conditions !== undefined) {
        displayedArray.push(isItemDisplayed([], state, opt.conditions));
      } else {
        displayedArray.push(true);
      }
    }

    return displayedArray;
  });

/***
 * returns array of booleans which holds information which dropdown options should be displayed
 * @param location of current question
 */
const getDropdownNewOptionsDisplayedArray = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsDropdownQuestion_New;
    const displayedArray: boolean[] = [];
    for (const opt of question.options.optValues) {
      if (opt.conditions !== undefined) {
        displayedArray.push(isItemDisplayed([], state, opt.conditions));
      } else {
        displayedArray.push(true);
      }
    }

    return displayedArray;
  });

/***
 * returns array of booleans which holds information which dropdown options should be displayed
 * @param location of current question
 */
const getRadioOptionsDisplayedArray = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsRadioQuestion;
    const displayedArray: boolean[] = [];
    for (const opt of question.options) {
      if (opt.conditions !== undefined) {
        displayedArray.push(isItemDisplayed([], state, opt.conditions));
      } else {
        displayedArray.push(true);
      }
    }

    return displayedArray;
  });

/***
 * returns objects of ZvjsNestedDropDownNewData which holds information which dropdown options should be displayed
 * @param location of current question
 */
const getNestedDropdownQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsNestedDropdown;
    const answers = state.answers[question.id] as
      | NestedDropdownAnswerValueType[]
      | undefined;
    const { getFallbackJazyk } = useUITranslation();

    /**
     * Function used to calculate {options: ZvjsNestedDropdownNewViewOptions, selectedLabels: string[]} for nested dropdown question from NestedDropdown model from request tamplate
     * @param options options of NestedDropdown from request template
     * @param parentTree used inside recursion to pass parentTree information (information where you are inside nested dropdown - so you are able to determine if option is checked)
     * @param selectedLabels used inside recursion to store labels of selected options
     */
    const recursion = (
      options: ZvjsNestedDropdownOptions,
      parentTree: { [key: string]: string } = {},
      selectedLabels: string[] = []
    ): {
      options: ZvjsNestedDropdownViewOptions;
      selectedLabels: string[];
    } => {
      const tmp: ZvjsNestedDropdownViewOptions = {
        answerId: options.answerId,
        children: [],
      };
      const counter = state.counters[options.counterKey];

      for (const iterData of options.children ?? []) {
        if (
          iterData.conditions === undefined
            ? true
            : isItemDisplayed([], state, iterData.conditions)
        ) {
          const isChecked = isNestedOptionChecked(answers, {
            [options.answerId]: iterData.id,
            ...parentTree,
          });

          const label = capitalize(
            getTranslationByLanguageCode(
              counter?.records ?? [],
              getCiselnikJazyk(i18n.language),
              getFallbackJazyk(),
              iterData.id,
              options.textKey
            )
          );

          if (isChecked) {
            // if an option is checked, add it into selectedLabel string
            selectedLabels.push(label);
          }

          tmp.children.push({
            key: iterData.id,
            label: label,
            options: iterData.options
              ? recursion(
                  iterData.options,
                  {
                    [options.answerId]: iterData.id,
                    ...parentTree,
                  },
                  selectedLabels
                ).options
              : undefined,
            // if is answered then check it
            checked: isChecked,
          });
        }
      }

      return { options: tmp, selectedLabels: selectedLabels };
    };

    const { options, selectedLabels } = recursion(question.options);

    return {
      id: question.id,
      title: question.title,
      options: options,
      answerRequired: question.isRequired,
      selectedOptionsText: selectedLabels.join(","),
      hintText: question.hintText,
      maxNumberSelect: question.maxNumberSelect,
      selectedOptionsCount: selectedLabels.length,
    };
  });

const getTextQuestionDisplayData = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate) => {
      const question = findItem(state, location) as ZvjsTextQuestion;
      return {
        id: question.id,
        title: question.title,
        inputType: question.inputType,
        multiline: question.multiline,
        minRows: question.minRows,
        hintText: question.hintText,
        isRequired: question.isRequired,
      };
    }
  );

const getSingleCheckBoxDisplayData = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate) => {
      const question = findItem(state, location) as ZvjsSingleCheckBoxQuestion;
      return {
        id: question.id,
        title: question.title,
        checked: question.checked,
      };
    }
  );

const getRadioQuestionDisplayData = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate) => {
      const { tuiz } = useUITranslation();
      const question = findItem(state, location) as ZvjsRadioQuestion;
      return {
        id: question.id,
        title: question.title,
        options: question.options.map((option) => {
          return {
            value: option.id,
            text: tuiz(option.label),
          };
        }),
        hintText: question.hintText,
        isRequired: question.isRequired,
        hideTitle: question.hideTitle,
      };
    }
  );

const getLabelData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const label = findItem(state.questionnaire, location) as ZvjsLabel;
    let text = label.text;
    text = text.replace(/#{([A-Za-z0-9_]+)}/g, (match, placeholder) => {
      // placeholder holds value inside brackets #{0} => 0
      const newVal = state.userCalcData[placeholder] as
        | UserCalcData
        | undefined;
      // if you have found new value use it in text string
      if (newVal !== undefined) {
        return newVal.toString();
      }
      // match holds original value
      return match;
    });

    return { text: text, flag: label.flag };
  });

const getLabelTextWithSingleValue = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const label = findItem(
      state.questionnaire,
      location
    ) as ZvjsLabelSingleValue;

    return {
      text: label.text,
      flag: label.flag,
      value: state.userCalcData[label.value],
      valueType: label.valueType,
    };
  });

const getQuestionAnswerData = (questionId: string) =>
  createSelector(
    selectSelf,
    (state: State): string | string[] | number | undefined => {
      return state.answers[questionId] as string | string[] | undefined;
    }
  );

const getDropdownQuestionAnswerData = (
  location: number[],
  questionId: string
) =>
  createSelector(selectSelf, (state: State): undefined | string => {
    // check if a selected option is still displayed/available to user
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsDropdownQuestion;
    for (const option of question.options) {
      // find a currently selected option object
      if (option.id === state.answers[question.id]) {
        if (Array.isArray(option.conditions)) {
          if (!isItemDisplayed([], state, option.conditions)) {
            // if item is not displayed anymore, return undefined instead
            return undefined;
          }
        }
        break;
      }
    }
    return state.answers[questionId] as string;
  });

const getDropdownQuestionNewAnswerData = (
  location: number[],
  questionId: string
) =>
  createSelector(selectSelf, (state: State): undefined | string => {
    // check if a selected option is still displayed/available to user
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsDropdownQuestion_New;
    for (const option of question.options.optValues) {
      // find a currently selected option object
      if (option.id === state.answers[question.id]) {
        if (Array.isArray(option.conditions)) {
          if (!isItemDisplayed([], state, option.conditions)) {
            // if item is not displayed anymore, return undefined instead
            return undefined;
          }
        }
        break;
      }
    }
    return state.answers[questionId] as string;
  });

const getRadioQuestionAnswerData = (location: number[], questionId: string) =>
  createSelector(selectSelf, (state: State): undefined | string => {
    // check if selected option is still displayed/available to user
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsRadioQuestion;
    for (const option of question.options) {
      // find currently selected option object
      if (option.id === state.answers[question.id]) {
        if (Array.isArray(option.conditions)) {
          if (!isItemDisplayed([], state, option.conditions)) {
            // if item is not displayed anymore return undefined instead
            return undefined;
          }
        }
        break;
      }
    }
    return state.answers[questionId] as string;
  });

const getGeneralQuestionInfo = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate) => {
      const foundItem = findItem(state, location) as ZvjsQuestion;
      return {
        type: foundItem.type,
        id: foundItem.id,
      };
    }
  );

const getIsItemDisplayed = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    return isItemDisplayed(location, state);
  });

const getGeneralItemInfo = (location: number[]) =>
  createSelector(
    selectQuestionnaireTemplate,
    (state: ZvjsQuestionnaireTemplate) => {
      const foundItem = findItem(state, location);
      return {
        type: foundItem.type,
      };
    }
  );

const getValidationError = (questionId: string) =>
  createSelector(
    selectValidationErrors,
    (state: {
      [key: string]: ZvjsValidationError;
    }): ZvjsValidationError | undefined => {
      return state[questionId];
    }
  );

// check if all required questions in questionnaire are answered
const allQuestionsAnswered = createSelector(selectSelf, (state: State) => {
  // check if you have answers from all displayed questions in displayed sections
  const iterator = questionsIteratorWithLocFromDisplayedSections(
    state.questionnaire,
    state
  );
  for (const question of iterator) {
    // check if question is required and displayed
    if (
      question.value.isRequired &&
      isItemDisplayed(question.location, state)
    ) {
      // if your question is custom, check if there is custom isAnswered question defined for this question
      if (question.value.type in ZvjsCustomQuestionTypes) {
        const questionType = question.value.type as ZvjsCustomQuestionTypes;
        const isAnsweredCustomFunc = customIsAnswered[questionType];
        if (isAnsweredCustomFunc !== undefined) {
          if (
            !isAnsweredCustomFunc(
              state.answers[question.value.id],
              state.userCalcData
            )
          ) {
            return false;
          }
          // if your custom question is defined, and it returned true, there is no need to do another check below for this question
          break;
        }
      }

      // check if the required question is already answered
      if (
        isEmptyString(state.answers[question.value.id]) ||
        isEmptyArray(state.answers[question.value.id])
      ) {
        return false;
      }

      // in dropdown question there is possibility that no longer displayed option is selected by user
      // TODO remove this check when removing ZvjsQuestionTypes.DROPDOWN - will be replaced by method below
      if (question.value.type === ZvjsQuestionTypes.DROPDOWN) {
        for (const questOption of (question.value as ZvjsDropdownQuestion)
          .options) {
          // find a selected option object
          if (questOption.id === state.answers[question.value.id]) {
            // check if option is displayed
            if (
              Array.isArray(questOption.conditions) &&
              !isItemDisplayed([], state, questOption.conditions)
            ) {
              return false;
            }
          }
        }
      }

      if (question.value.type === ZvjsQuestionTypes.DROPDOWN_NEW) {
        for (const questOption of (question.value as ZvjsDropdownQuestion_New)
          .options.optValues) {
          // find a selected option object
          if (questOption.id === state.answers[question.value.id]) {
            // check if option is displayed
            if (
              Array.isArray(questOption.conditions) &&
              !isItemDisplayed([], state, questOption.conditions)
            ) {
              return false;
            }
          }
        }
      }

      if (question.value.type === ZvjsQuestionTypes.NESTED_DROPDOWN) {
        const nestedIterator =
          questionsIteratorWithLocAndParentTreeNestedDropdown(
            (question.value as ZvjsNestedDropdown).options
          );
        let atLeastOneSelectedOptionIsDisplayed = false;
        for (const nestedOption of nestedIterator) {
          if (
            isNestedOptionChecked(
              state.answers[
                question.value.id
              ] as NestedDropdownAnswerValueType[],
              nestedOption.parentTree
            ) &&
            isItemDisplayed([], state, nestedOption.currentItemConditions)
          ) {
            atLeastOneSelectedOptionIsDisplayed = true;
          }
        }
        if (!atLeastOneSelectedOptionIsDisplayed) {
          return false;
        }
      }
    }

    if (isItemDisplayed(question.location, state)) {
      // if there is a validation error displayed for any question (not only required), questionnaire cannot be sent
      if (state.validationErrors[question.value.id] !== undefined) {
        return false;
      }
    }
  }
  return true;
});

const allSelectors = {
  getRootItems,
  getRequestDisplayData,
  getIntroductionDisplayData,
  getHasQuestionnaireAnyItems,
  getSectionItems,
  getSectionTitles,
  getGeneralCustomQuestionDisplayData,
  getDropdownQuestionDisplayData,
  getDropdownQuestionNewDisplayData,
  getDropdownOptionsDisplayedArray,
  getDropdownNewOptionsDisplayedArray,
  getRadioOptionsDisplayedArray,
  getTextQuestionDisplayData,
  getSingleCheckBoxDisplayData,
  getRadioQuestionDisplayData,
  getLabelData,
  getQuestionAnswerData,
  getDropdownQuestionAnswerData,
  getDropdownQuestionNewAnswerData,
  getRadioQuestionAnswerData,
  getGeneralQuestionInfo,
  getIsItemDisplayed,
  getGeneralItemInfo,
  allQuestionsAnswered,
  getValidationError,
  getQuestionToEdit,
  getNestedDropdownQuestionDisplayData,
  getLabelTextWithSingleValue,
  ...allSummarySelectors,
  ...allCustomQuestionSelectors,
};

export const getRequestCodeFromCounter = (
  counter: CiselnikListResultType<"/api/CisTypZiadosti/List">["data"]
): string => {
  if (
    counter?.records === undefined ||
    counter?.records === null ||
    isEmptyArray(counter?.records) ||
    counter?.records[0].kod === undefined ||
    counter?.records[0].kod === null
  ) {
    console.error("Request code not found in requestCounter");
    throw new Error("Request code not found in requestCounter");
  }

  return counter.records[0].kod;
};

export const getRequestTitle = (
  counter: CiselnikListResultType<"/api/CisTypZiadosti/List">["data"],
  requestCode: string,
  fallbackJazyk: string
): string => {
  return getTranslationByLanguageCode(
    counter?.records ?? [],
    getCiselnikJazyk(i18n.language),
    fallbackJazyk,
    requestCode,
    "nazov"
  );
};

//
/**
 * Return only answers which are currently available to user.
 * Used to filter out not available options in DROPDOWN_NEW, DROPDOWN and NESTED_DROPDOWN questions.
 * If there is already a selected option that is currently not available to user, do not return it.
 */
export const getAvailableAnswersForQuestion = (
  answer: ZvjsAnswerValueType,
  question: ZvjsQuestion,
  state: State
): ZvjsAnswerValueType | undefined => {
  if (question.type === ZvjsQuestionTypes.DROPDOWN_NEW) {
    for (const opt of (question as ZvjsDropdownQuestion_New).options
      .optValues) {
      if (
        opt.id === answer &&
        isItemDisplayed([], state, opt.conditions ?? [])
      ) {
        return answer;
      }
    }
    return undefined;
  }

  if (question.type === ZvjsQuestionTypes.DROPDOWN) {
    for (const opt of (question as ZvjsDropdownQuestion).options) {
      if (
        opt.id === answer &&
        isItemDisplayed([], state, opt.conditions ?? [])
      ) {
        return answer;
      }
    }
    return undefined;
  }

  if (question.type === ZvjsQuestionTypes.NESTED_DROPDOWN) {
    const nestedIterator = questionsIteratorWithLocAndParentTreeNestedDropdown(
      (question as ZvjsNestedDropdown).options
    );
    const filteredAnswer: NestedDropdownAnswerValueType[] = [];
    for (const nestedOption of nestedIterator) {
      if (
        isNestedOptionChecked(
          answer as NestedDropdownAnswerValueType[],
          nestedOption.parentTree
        ) &&
        isItemDisplayed([], state, nestedOption.currentItemConditions)
      ) {
        filteredAnswer.push(nestedOption.parentTree);
      }
    }
    return filteredAnswer;
  }

  return answer;
};

/***
 * Function returns information if item (question, section, option, anything should be displayed)
 * @param location location of item (for question, section, label etc.). Dropdown options do not have a location
 * @param state redux state to access user answers
 * @param paConditions you can pass to this function item conditions instead of item location => in this case location is not used to check conditions
 */
export const isItemDisplayed = (
  location: number[],
  state: State,
  paConditions: ZvjsFormCondition[] | undefined = undefined
): boolean => {
  let conditions: ZvjsFormCondition[] | undefined;
  if (paConditions === undefined) {
    conditions = (findItem(state.questionnaire, location) as ZvjsGeneralItem)
      .conditions;
  } else {
    conditions = paConditions;
  }

  if (Array.isArray(conditions)) {
    // iterate through conditions
    for (const cond of conditions) {
      if (cond.type === ZvjsConditionTypes.PREVIOUS_ANSWERS) {
        const iterator = questionsIteratorWithLocation(state.questionnaire);
        for (const question of iterator) {
          // find question in questionnaire related to the condition
          if (question.value.id === cond.questionId) {
            // find answer for question
            const answer = getAvailableAnswersForQuestion(
              state.answers[question.value.id],
              question.value,
              state
            );

            // if a question is not answered yet or question is not even displayed, condition is not met
            if (
              answer === undefined ||
              isEmptyString(answer) ||
              isEmptyArray(answer) ||
              !isItemDisplayed(question.location, state)
            ) {
              return false;
            }

            // if there is a value array present check if any of the provided options matches
            if (Array.isArray(cond.value)) {
              // check if any value in condition.value array is the same as user answer
              let match = false;

              if (question.value.type === ZvjsQuestionTypes.NESTED_DROPDOWN) {
                for (const condVal of cond.value) {
                  if (
                    isNestedOptionChecked(
                      // if you are checking condition against nested dropdown question answer has type NestedDropdownAnswerValueType
                      answer as NestedDropdownAnswerValueType[],
                      condVal as NestedDropdownAnswerValueType
                    )
                  ) {
                    match = true;
                    break;
                  }
                }
              } else {
                // if you are checking condition against a standard question type do standard check
                // (checks against custom questions must be done via function conditions)
                if (!Array.isArray(answer)) {
                  // if answer var holds a single value path
                  for (let j = 0; j < cond.value.length; j++) {
                    const condVal = cond.value[j];
                    if (
                      cond.value[j] === answer &&
                      typeof answer === "string" &&
                      // answer value against standard question should always be string
                      typeof condVal === "string"
                    ) {
                      const regex = new RegExp(condVal);
                      if (regex.test(answer)) {
                      }
                      match = true;
                      break;
                    }
                  }
                } else {
                  // if answer var holds an array path (multiple answers for single question)
                  for (let i = 0; i < answer.length; i++) {
                    for (let j = 0; j < cond.value.length; j++) {
                      if (answer[i] === cond.value[j]) {
                        match = true;
                        break;
                      }
                    }
                    if (match) {
                      break;
                    }
                  }
                }
              }

              if (!match) {
                // if there is no match, question should be not displayed
                return false;
              }
            }

            // check if related answer holds any value, if not do not display question
            if (cond.hasValue === true && isEmptyString(answer)) {
              return false;
            }
          }
        }
      }

      if (cond.type === ZvjsConditionTypes.FUNCTION) {
        if (state.questionnaire.dataCalculations === undefined) {
          console.warn(
            "Questionnaire template is invalid: There is USER_INFO type condition and dataCalculations object is undefined!"
          );
        } else {
          switch (
            state.questionnaire.dataCalculations[cond.conFunc]?.calculateAt
          ) {
            case Calculate.AT_INIT:
              if (cond.conditionMetWhenValueIs) {
                // if you expect value to be true, do not show item when calculated value is false
                if (!(state.userCalcData[cond.conFunc] as boolean)) {
                  return false;
                }
              } else {
                // if you expect value to be false, do not show item when calculated value is true
                if (state.userCalcData[cond.conFunc] as boolean) {
                  return false;
                }
              }
              break;
            case Calculate.LIVE:
              const result = calculations[cond.conFunc]({
                userCalcData: state.userCalcData,
                answers: state.answers,
              });
              if (typeof result !== "boolean") {
                console.warn(
                  "USER_INFO condition function returned not bool value!"
                );
                return false;
              } else {
                if (cond.conditionMetWhenValueIs) {
                  // if you expect value to be true, do not show item when calculated value is false
                  if (!result) {
                    return false;
                  }
                } else {
                  // if you expect value to be false, do not show item when calculated value is true
                  if (result) {
                    return false;
                  }
                }
              }
          }
        }
      }
    }
  }
  return true;
};

/**
 * Nested dropdown helper function which check if provided option is checked in answers
 */
export const isNestedOptionChecked = (
  answers: NestedDropdownAnswerValueType[] | undefined,
  optionToVerify: { [key: string]: string }
) => {
  if (answers) {
    for (const answer of answers) {
      let match = true;
      for (const key of Object.keys(answer)) {
        if (optionToVerify[key] !== answer[key]) {
          match = false;
        }
      }
      if (match) {
        return true;
      }
    }
  }
  return false;
};

export default allSelectors;
