import { Button, Grid, Stack, Typography } from "@mui/material";
import { Params, useNavigate } from "react-router-dom";
import { defer } from "react-router-dom";
import { withLoader } from "../../../hoc/withLoader";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as KeyboardArrowRightIcon } from "../../../assets/icons/Other/keyboard_arrow_right.svg";
import { useUITranslation } from "../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { Dashboard_SC_KIO_0200, ZvjsButton, ZvjsTable } from "../../../common";
import {
  requestTemplates,
  SC_KIO_050101_ZiadostONavstevu,
  SC_KIO_050102_ZiadostOMimoriadnuNavstevuZDovoduDisciplinarnejOdmeny,
  SC_KIO_050201_ZiadostOVymenuCudzozemskychPlatidiel,
  SC_KIO_050202_ZiadostOVydanieZaevidovanychCeninCennychPapierovAI,
  SC_KIO_050203_ZiadostODobrovolnuUhraduVyzivnehoAleboPohladavky,
  SC_KIO_050204_ZiadostOPoukazaniePenaznychProstriedkovVProspechOsobAleboSubjektov,
  SC_KIO_050205_ZiadostOZaevidovaniePPNaVreckoveKontoOdsudenehoUmiestnenehoVOO,
  SC_KIO_050206_ZiadostOVykonanieZrazokZCPOOdsudenehoNaUhraduVyzivnehoNezaopatrenychDeti,
  SC_KIO_050207_ZiadostOVyplateniePPPriUdeleniMVOU,
  SC_KIO_050208_ZiadostOVyplateniePPZKontaOdsudenehoPremiestnenehoDoOO,
  SC_KIO_050209_ZiadostODalsiePouItieVratenejPlatbyZDobrovolnejUhradyVyzivnehoAleboPohladavky,
  SC_KIO_050301_ZiadostOOdoslanieKorespondencieNaNakladyUstavu,
  SC_KIO_050302_ZiadostOVydaniePovoleniaNaPrijatieBalika,
  SC_KIO_050401_ZiadostOPoskytnutieAmbulantnejZdravotnejStarostlivosti,
  SC_KIO_050402_ZiadostOPovolenieZdravotnejPomocky,
  SC_KIO_050501_ZiadostOPouzivanieVlastnehoElektrospotrebicaAAleboJehoTechnickuKontrolu,
  SC_KIO_050502_ZiadostOVydanieVeciUlozenychVUschoveAleboVeciVUzivani,
  SC_KIO_050601_ZiadostOUcastNaHromadnejKukturnoOsvetovejASportovejAktivite,
  SC_KIO_050602_ZiadostOZaradenieDoSpecializovanehoResocializacnehoAVychovnoVzdelavaciehoProgramu,
  SC_KIO_050603_ZiadostOPrijatieDoVzdelavaciehoProgramuAleboRekvalifikacnehoKurzu,
  SC_KIO_050604_ZiadostOZaradenieDoKruzku,
  SC_KIO_050701_ZiadostOTelefonovanie,
  SC_KIO_050801_ZiadostOPohovorSoSocialnymPracovnikomSCielomZabezpeceniaPrislusnejSocialnejSluzby,
  SC_KIO_050802_ZiadostOPoskytnutiePomociPriPrepusteniOdevStravaCestovnyListokAPod,
  SC_KIO_050803_ZiadostOPoskytnutiePsychologickejSluzby,
  SC_KIO_050804_ZiadostOPoskytnutieDuchovnejAPastoracnejSluzby,
  SC_KIO_050901_ZiadostOPohovorSRiaditelomUstavuAleboPoskytnutieInformacieInymPracovnikomZboru,
  SC_KIO_051001_ZiadostOCastejsieSprchovanie,
  SC_KIO_051002_ZiadostOOstrihanie,
  SC_KIO_051003_ZiadostOPoskytnutiePotriebOsobnejHygieny,
  SC_KIO_051004_ZiadostONakupSortimentuVUstavnejPredajniVObmedzenomRozsahu,
  SC_KIO_051102_ZiadostOPouzivanieVlastnehoOdevuBielizneObuvi,
  SC_KIO_051104_ZiadostOObmenuOdevuBielizneAObuviUstavnychVystrojnychSucasti,
  SC_KIO_051201_ZiadostOZmenuPoskytovanehoDruhuStravy,
  SC_KIO_051301_HlaseniePoruchVObjekteVykonuVazbyAVykonuTrestu,
  SC_KIO_051401_ZiadostOUmiestnenieVOddieleSoStandardnymRezimom,
  SC_KIO_051402_ZiadostOUmiestnenieVRamciAktualnehoUstavu,
  SC_KIO_051403_ZiadostOdsudenehoOPremiestnenie,
  SC_KIO_051404_ZiadostOdsudenehoOUmiestnenie,
  SC_KIO_051501_UniverzalnaZiadost,
} from "../../../common/request/requestTemplates";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/CIS_Ciselnik";
import { LoaderError } from "../../../router/LoaderError";
import {
  getTranslationByLanguageCode,
  getUniqueCodesFromCiselnik,
} from "../../../utils/helpers";
import { getCiselnikJazyk } from "../../../locales/i18n";
import i18n from "i18next";
import isAvailableToUserFunctions from "../../../common/request/requestPages/questionnaire/utils/isAvailableToUser";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { RequestSzooCode } from "../../../common/request/requestTemplates/requestTemplates";

const LoadData = async (requestCategoryCode?: string) => {
  const { CIS_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisPodkategoriaZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    CIS_Post("/api/CisTypZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    CIS_Post("/api/CisKategoriaZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  // filter out all subCategories that belong to the specified category
  const selectedSubCategories =
    data[0].data?.records?.filter(
      (subCategory) =>
        subCategory.kategoriaId?.toString() === requestCategoryCode
    ) || [];

  const selectedRequestsCounter = {
    records: data[1].data?.records?.filter(
      (request) =>
        // show only supported requests
        request.kod !== undefined &&
        request.kod !== null &&
        requestTemplates[request.kod as `${RequestSzooCode}`] !== undefined &&
        // show requests that belong to the selected subCategories
        selectedSubCategories.some(
          (subCategory) =>
            subCategory.kod === request.podKategoriaId?.toString()
        )
    ),
  };

  // check for all found requests if they should be displayed for logged in user
  const shouldBeDisplayedRequestsList = await Promise.all(
    (selectedRequestsCounter.records ?? []).map(async (request) => {
      if (
        request.kod !== undefined &&
        request.kod !== null &&
        !!requestTemplates[request.kod as `${RequestSzooCode}`]
      ) {
        const requestTemplate =
          requestTemplates[request.kod as `${RequestSzooCode}`];
        if (!!requestTemplate.isAvailableToUser) {
          return {
            request,
            isAvailableToUser: await isAvailableToUserFunctions[
              requestTemplate.isAvailableToUser
            ](),
          };
        } else {
          // if isAvailableToUser function is not provided, the request is supposed to be displayed to the client everytime
          return { request, isAvailableToUser: true };
        }
      } else {
        // if request with code is not supported, do not display it
        return { request, isAvailableToUser: false };
      }
    })
  );

  // update the selected requests list after isAvailableToUser functions are evaluated
  selectedRequestsCounter.records = shouldBeDisplayedRequestsList
    .filter(({ isAvailableToUser }) => isAvailableToUser)
    .map(({ request }) => request);

  // if categories counter was not fetched, display error page
  if (data[2].data?.records === undefined) {
    throw new LoaderError();
  }

  const selectedCategory = data[2].data?.records?.find(
    (category) => category.kod === requestCategoryCode
  );
  // if selected category was not found in counter, display error page
  if (selectedCategory === undefined) {
    throw new LoaderError();
  }

  const selectedCategoryCounter = {
    records: [selectedCategory],
  };

  return { selectedRequestsCounter, selectedCategoryCounter };
};

export const RequestCategoryLoader = async ({
  request,
  params,
}: {
  request: Request;
  params: Params;
}) => {
  return defer({
    data: LoadData(params.requestCategoryCode),
  });
};

interface RequestCategoryProps {
  data: Awaited<ReturnType<typeof LoadData>>;
}

const implementedRequestsList = [
  // SC_KIO_050101_ZiadostONavstevu,
  // SC_KIO_050901_ZiadostOPohovorSRiaditelomUstavuAleboPoskytnutieInformacieInymPracovnikomZboru,
  SC_KIO_050205_ZiadostOZaevidovaniePPNaVreckoveKontoOdsudenehoUmiestnenehoVOO,
  SC_KIO_050207_ZiadostOVyplateniePPPriUdeleniMVOU,
  SC_KIO_050208_ZiadostOVyplateniePPZKontaOdsudenehoPremiestnenehoDoOO,
  SC_KIO_050402_ZiadostOPovolenieZdravotnejPomocky,
  SC_KIO_051001_ZiadostOCastejsieSprchovanie,
  SC_KIO_051002_ZiadostOOstrihanie,
  SC_KIO_051201_ZiadostOZmenuPoskytovanehoDruhuStravy,
  SC_KIO_051301_HlaseniePoruchVObjekteVykonuVazbyAVykonuTrestu,
  SC_KIO_051402_ZiadostOUmiestnenieVRamciAktualnehoUstavu,
  SC_KIO_051501_UniverzalnaZiadost,
];

const requestsList = [
  SC_KIO_050101_ZiadostONavstevu,
  SC_KIO_050102_ZiadostOMimoriadnuNavstevuZDovoduDisciplinarnejOdmeny,
  SC_KIO_050201_ZiadostOVymenuCudzozemskychPlatidiel,
  SC_KIO_050202_ZiadostOVydanieZaevidovanychCeninCennychPapierovAI,
  SC_KIO_050203_ZiadostODobrovolnuUhraduVyzivnehoAleboPohladavky,
  SC_KIO_050204_ZiadostOPoukazaniePenaznychProstriedkovVProspechOsobAleboSubjektov,
  SC_KIO_050205_ZiadostOZaevidovaniePPNaVreckoveKontoOdsudenehoUmiestnenehoVOO,
  SC_KIO_050206_ZiadostOVykonanieZrazokZCPOOdsudenehoNaUhraduVyzivnehoNezaopatrenychDeti,
  SC_KIO_050207_ZiadostOVyplateniePPPriUdeleniMVOU,
  SC_KIO_050208_ZiadostOVyplateniePPZKontaOdsudenehoPremiestnenehoDoOO,
  SC_KIO_050209_ZiadostODalsiePouItieVratenejPlatbyZDobrovolnejUhradyVyzivnehoAleboPohladavky,
  SC_KIO_050301_ZiadostOOdoslanieKorespondencieNaNakladyUstavu,
  SC_KIO_050302_ZiadostOVydaniePovoleniaNaPrijatieBalika,
  SC_KIO_050401_ZiadostOPoskytnutieAmbulantnejZdravotnejStarostlivosti,
  SC_KIO_050402_ZiadostOPovolenieZdravotnejPomocky,
  SC_KIO_050501_ZiadostOPouzivanieVlastnehoElektrospotrebicaAAleboJehoTechnickuKontrolu,
  SC_KIO_050502_ZiadostOVydanieVeciUlozenychVUschoveAleboVeciVUzivani,
  SC_KIO_050601_ZiadostOUcastNaHromadnejKukturnoOsvetovejASportovejAktivite,
  SC_KIO_050602_ZiadostOZaradenieDoSpecializovanehoResocializacnehoAVychovnoVzdelavaciehoProgramu,
  SC_KIO_050603_ZiadostOPrijatieDoVzdelavaciehoProgramuAleboRekvalifikacnehoKurzu,
  SC_KIO_050604_ZiadostOZaradenieDoKruzku,
  SC_KIO_050701_ZiadostOTelefonovanie,
  SC_KIO_050801_ZiadostOPohovorSoSocialnymPracovnikomSCielomZabezpeceniaPrislusnejSocialnejSluzby,
  SC_KIO_050802_ZiadostOPoskytnutiePomociPriPrepusteniOdevStravaCestovnyListokAPod,
  SC_KIO_050803_ZiadostOPoskytnutiePsychologickejSluzby,
  SC_KIO_050804_ZiadostOPoskytnutieDuchovnejAPastoracnejSluzby,
  SC_KIO_050901_ZiadostOPohovorSRiaditelomUstavuAleboPoskytnutieInformacieInymPracovnikomZboru,
  SC_KIO_051001_ZiadostOCastejsieSprchovanie,
  SC_KIO_051002_ZiadostOOstrihanie,
  SC_KIO_051003_ZiadostOPoskytnutiePotriebOsobnejHygieny,
  SC_KIO_051004_ZiadostONakupSortimentuVUstavnejPredajniVObmedzenomRozsahu,
  SC_KIO_051102_ZiadostOPouzivanieVlastnehoOdevuBielizneObuvi,
  SC_KIO_051104_ZiadostOObmenuOdevuBielizneAObuviUstavnychVystrojnychSucasti,
  SC_KIO_051201_ZiadostOZmenuPoskytovanehoDruhuStravy,
  SC_KIO_051301_HlaseniePoruchVObjekteVykonuVazbyAVykonuTrestu,
  SC_KIO_051401_ZiadostOUmiestnenieVOddieleSoStandardnymRezimom,
  SC_KIO_051402_ZiadostOUmiestnenieVRamciAktualnehoUstavu,
  SC_KIO_051403_ZiadostOdsudenehoOPremiestnenie,
  SC_KIO_051404_ZiadostOdsudenehoOUmiestnenie,
  SC_KIO_051501_UniverzalnaZiadost,
];

const RequestCategory = (props: RequestCategoryProps) => {
  const { selectedRequestsCounter, selectedCategoryCounter } = props.data;
  const navigate = useNavigate();
  const { tui, getFallbackJazyk } = useUITranslation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const dataStyleRequestsInCategory: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
      width: 250,
    },
  ];

  const headerDataRequestsInCategory: Array<CellData> = [
    {
      value: capitalize(
        tui(
          "Názov žiadosti" // TODO: use proper KEY
        )
      ),
      ...dataStyleRequestsInCategory[0],
    },
    {
      value: capitalize(
        tui(
          "Akcia" // TODO: use proper KEY
        )
      ),
      ...dataStyleRequestsInCategory[1],
    },
  ];

  const getTableBody = (): Array<RowData> => {
    const counterCodes = getUniqueCodesFromCiselnik(
      selectedRequestsCounter?.records ?? []
    );

    const requests = counterCodes.map((code) => {
      return {
        requestCode: code,
        title: getTranslationByLanguageCode(
          selectedRequestsCounter?.records ?? [],
          getCiselnikJazyk(i18n.language),
          getFallbackJazyk(),
          code,
          "nazov"
        ),
      };
    });

    const bodyData: Array<RowData> = new Array<RowData>();
    requests.forEach((request) => {
      bodyData.push({
        row: [
          {
            value: request.title,
            ...dataStyleRequestsInCategory[0],
          },
          {
            value: (
              <ZvjsButton
                // TODO: use proper KEY
                text={capitalize("vybrať žiadosť")}
                zvjsVariant="secondaryAction"
                endIcon={
                  <KeyboardArrowRightIcon
                    style={{ fill: ZVJS_COLORS.WHITE, width: 28, height: 28 }}
                  />
                }
                onClick={() => {
                  navigate(
                    `../${Dashboard_SC_KIO_0200.Requests}/${request.requestCode}`
                  );
                }}
              />
            ),
            ...dataStyleRequestsInCategory[1],
          },
        ],
      });
    });

    return bodyData;
  };

  const getPageTitle = (): string => {
    if (!!selectedCategoryCounter?.records) {
      // TODO update when will categories counter support localization
      return selectedCategoryCounter.records[0].nazov ?? "";
    }
    return "";
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} width={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {/*TODO replace with category name - translated value from counter*/}
              {getPageTitle()}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTable
            data={{
              header: headerDataRequestsInCategory,
              body: getTableBody(),
            }}
            variant={ZvjsTableVariant.NORMAL}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(RequestCategory);
