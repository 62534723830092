import { capitalize } from "@mui/material";
import { ZvjsNumpad } from "../../common";
import { LoginStepProps } from "../Login";
import { useUITranslation } from "../../store/context/translation-context";
import AuthContext from "../../store/context/auth-context";
import { useContext } from "react";
import { useSnackbar } from "../../store/context/snackbar-context";

const LoginStep2: React.FC<LoginStepProps> = ({ setStep, data }) => {
  const { tui } = useUITranslation();
  const { openSnackbar } = useSnackbar();

  const { cisloKarty } = useContext(AuthContext);

  const onCheck = (pin: string) => {
    const karta = data.listIdKariet.data?.records?.find(
      (karta) => karta.cisloKarty === Number(cisloKarty)
    );

    const spravnyPin =
      karta?.pin === Number(pin) ||
      karta?.docasnyPin === Number(pin) ||
      ((karta?.pin === undefined || karta?.pin === null) &&
        (karta?.docasnyPin === undefined || karta?.docasnyPin === null));
    if (spravnyPin) {
      setStep(3);
    } else {
      const message = capitalize(tui("Nesprávny PIN"));
      console.error(message);
      openSnackbar(message, "error");
    }
  };

  return <ZvjsNumpad pinLength={4} onCheck={onCheck} />;
};

export default LoginStep2;
