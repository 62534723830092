import React, { useEffect, useState } from "react";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { Paper, Stack } from "@mui/material";
import ZvjsButton from "./ZvjsButton";
import { ArrowForwardIos } from "@mui/icons-material";
import { API_Clients } from "../../store/context/dataApi/Data";
import ZvjsSelect, { ZvjsSelectItem } from "./ZvjsSelect";
import ZvjsTextField from "./ZvjsTextField";

interface ZvjsTesterReaderProps {
  onScan: (qrCode: string) => void;
}

const ZvjsTesterReader: React.FC<ZvjsTesterReaderProps> = ({ onScan }) => {
  const { tui } = useUITranslation();

  const [loginData, setLoginData] = useState<ZvjsSelectItem[]>([]);
  const [typedCardId, setTypedCardId] = useState<string>();
  const [selectedCardId, setSelectedCardId] = useState<string>("");

  useEffect(() => {
    API_Clients().then((clients) => {
      clients.SIDKOO_Post("/api/IdKarta/ListIdKarta").then((result) => {
        const selectItems: ZvjsSelectItem[] = [];
        result.data?.records
          ?.sort((k1, k2) => (k1.klientId ?? 0) - (k2.klientId ?? 0))
          //?.filter((karta) => karta.stavIdKartyKod === "A")
          ?.forEach((karta) => {
            selectItems.push({
              value: `${karta.cisloKarty}`,
              // text: `${karta.cisloKarty} - Klient [${karta.klientId}] [stav:${karta.stavIdKartyKod}] [pin:${karta.pin}]`,
              text: `${karta.cisloKarty} - [klientId ${karta.klientId}]`,
            });
          });
        setLoginData(selectItems);
      });
    });
  }, []);

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Stack direction={"column"} alignItems={"center"} spacing={1}>
        <Stack sx={{ m: 1 }} width={"100%"}>
          <ZvjsTextField
            label="Karta (text)"
            value={typedCardId}
            placeholder="číslo karty"
            //hintText="Zadajte číslo prihlasovacej karty"
            onChange={(e) => {
              setTypedCardId(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onScan(typedCardId?.trim() ?? "");
              }
            }}
          />
          <ZvjsButton
            name="typedPrihlasenie"
            text={capitalize(tui("prihlasenie.prihlasitSa"))}
            endIcon={<ArrowForwardIos />}
            zvjsVariant={"secondaryAction"}
            disabled={typedCardId == undefined || typedCardId?.trim() === ""}
            onClick={() => onScan(typedCardId?.trim() ?? "")}
          />
        </Stack>
        <Stack sx={{ m: 1 }} width={"100%"}>
          <ZvjsSelect
            id={"testerUserSelect"}
            text="Karta (výber)"
            items={loginData}
            controlled={{
              selectedValue: selectedCardId,
              changeHandler: (event) => setSelectedCardId(event.target.value),
            }}
          />
          <ZvjsButton
            name="selectedPrihlasenie"
            text={capitalize(tui("prihlasenie.prihlasitSa"))}
            endIcon={<ArrowForwardIos />}
            zvjsVariant={"secondaryAction"}
            disabled={selectedCardId == undefined}
            onClick={() => onScan(selectedCardId ?? "")}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ZvjsTesterReader;
