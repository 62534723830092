import { paths as auditing_v1_paths } from "../../../api/types/auditing_v1";
import { paths as cis_v1_paths } from "../../../api/types/cis_v1";
import { paths as edpoo_v1_paths } from "../../../api/types/edpoo_v1";
import { paths as eoo_v1_paths } from "../../../api/types/eoo_v1";
import { paths as epvvvt_v1_paths } from "../../../api/types/epvvvt_v1";
import { paths as er_v1_paths } from "../../../api/types/er_v1";
import { paths as ezoo_v1_paths } from "../../../api/types/ezoo_v1";
import { paths as feoo_v1_paths } from "../../../api/types/feoo_v1";
import { paths as hasura_v1_paths } from "../../../api/types/hasura_v1";
import { paths as rezervacia_knih_openapi_v1_paths } from "../../../api/types/rezervacia-knih-openapi_v1";
import { paths as share_int_v1_paths } from "../../../api/types/share-int_v1";
import { paths as sidkoo_v1_paths } from "../../../api/types/sidkoo_v1";
import { paths as szoo_v1_paths } from "../../../api/types/szoo_v1";
import { paths as zvjs_mp_v1_paths } from "../../../api/types/zvjs-mp_v1";

import { Module } from "../../../utils/module";
import { BaseApiCallHeaders } from "../../../utils/targetApi";

import { dbPromise } from "../../../services/database";
import { KEY_SVC_URL } from "../envConfigContext";

import createClient, { Middleware, RequestOptions } from "openapi-fetch";

const host = async (): Promise<string | undefined> => {
  const database = await dbPromise;
  return database.get("EnvironmentVariables", KEY_SVC_URL);
};

export const API_Clients = async () => {
  const middleware: Middleware = {
    async onRequest({ request, options }) {
      let routeDescription = `Request: module[${request.headers.get(
        "X-Zvjs-Module"
      )}] method[${request.method}] api[${request.url}]`;

      if (request.method === "POST" || request.method === "PUT") {
        routeDescription += ` body[${await request.clone().text()}]`;
      }

      console.debug(routeDescription);
    },

    async onResponse({ request, response, options }) {
      let routeDescription = `Response: module[${request.headers.get(
        "X-Zvjs-Module"
      )}] method[${request.method}] api[${request.url}] response[${
        response.ok ? "ok" : "error"
      }] status[${response.status}/${response.statusText}]`;

      // if (request.method === "POST" || request.method === "PUT") {
      //   routeDescription += ` body[${JSON.stringify(await request.json())}]`;
      // }

      if (response.ok) {
        console.info(routeDescription);
      } else {
        routeDescription += ` error[${await response.clone().text()}]`;
        console.error(routeDescription);
      }
    },
  };

  const auditingClient = createClient<auditing_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.AUDITING,
    },
  });

  const cisClient = createClient<cis_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.CIS },
  });

  const edpooClient = createClient<edpoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.EDPOO },
  });

  const eooClient = createClient<eoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.EOO },
  });

  const epvvvtClient = createClient<epvvvt_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.EPVVVT,
    },
  });

  const erClient = createClient<er_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.ER,
    },
  });

  const ezooClient = createClient<ezoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.EZOO },
  });

  const feooClient = createClient<feoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.FEOO },
  });

  const hasuraClient = createClient<hasura_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.HASURA,
    },
  });

  const rezervaciaKnihClient = createClient<rezervacia_knih_openapi_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.ZVJS_MP,
    },
  });

  const shareIntClient = createClient<share_int_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.SHARE_INT,
    },
  });

  const sidkooClient = createClient<sidkoo_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.SIDKOO,
    },
  });

  const szooClient = createClient<szoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...(await BaseApiCallHeaders()), "X-Zvjs-Module": Module.SZOO },
  });

  const szooLocalClient = createClient<szoo_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.SZOO,
      "X-Zvjs-Szoo": "local",
    },
  });

  const zvjsMpClient = createClient<zvjs_mp_v1_paths>({
    baseUrl: await host(),
    headers: {
      ...(await BaseApiCallHeaders()),
      "X-Zvjs-Module": Module.ZVJS_MP,
    },
  });

  auditingClient.use(middleware);
  cisClient.use(middleware);
  edpooClient.use(middleware);
  eooClient.use(middleware);
  epvvvtClient.use(middleware);
  erClient.use(middleware);
  ezooClient.use(middleware);
  feooClient.use(middleware);
  hasuraClient.use(middleware);
  rezervaciaKnihClient.use(middleware);
  shareIntClient.use(middleware);
  sidkooClient.use(middleware);
  szooClient.use(middleware);
  zvjsMpClient.use(middleware);

  const { GET: AUDITING_Get, POST: AUDITING_Post } = auditingClient;
  const { GET: CIS_Get, POST: CIS_Post } = cisClient;
  const { GET: EDPOO_Get, POST: EDPOO_Post } = edpooClient;
  const { GET: EOO_Get, POST: EOO_Post } = eooClient;
  const { GET: EPVVVT_Get, POST: EPVVVT_Post } = epvvvtClient;
  const { POST: ER_Post } = erClient;
  const { GET: EZOO_Get, POST: EZOO_Post } = ezooClient;
  const { GET: FEOO_Get, POST: FEOO_Post } = feooClient;
  const { GET: HASURA_Get, POST: HASURA_Post, PUT: HASURA_Put } = hasuraClient;
  const { GET: REZERVACIA_KNIH_Get, POST: REZERVACIA_KNIH_Post } =
    rezervaciaKnihClient;
  const { GET: SHARE_INT_Get, POST: SHARE_INT_Post } = shareIntClient;
  const { GET: SIDKOO_Get, POST: SIDKOO_Post } = sidkooClient;
  const { GET: SZOO_Get, POST: SZOO_Post } = szooClient;
  const { GET: SZOO_Local_Get, POST: SZOO_Local_Post } = szooLocalClient;
  const { GET: ZVJS_MP_Get, POST: ZVJS_MP_Post } = zvjsMpClient;

  return {
    AUDITING_Get,
    AUDITING_Post,
    CIS_Get,
    CIS_Post,
    EDPOO_Get,
    EDPOO_Post,
    EOO_Get,
    EOO_Post,
    EPVVVT_Get,
    EPVVVT_Post,
    ER_Post,
    EZOO_Get,
    EZOO_Post,
    FEOO_Get,
    FEOO_Post,
    HASURA_Get,
    HASURA_Post,
    HASURA_Put,
    REZERVACIA_KNIH_Get,
    REZERVACIA_KNIH_Post,
    SHARE_INT_Get,
    SHARE_INT_Post,
    SIDKOO_Get,
    SIDKOO_Post,
    SZOO_Get,
    SZOO_Post,
    SZOO_Local_Get,
    SZOO_Local_Post,
    ZVJS_MP_Get,
    ZVJS_MP_Post,
  };
};

/**
 * Helper medhod to retrieve APIs FetchOption types
 * DON'T CALL THIS METHOD
 */
const internalDataRequestOptionsTypes = () => {
  // AUDITING

  const auditing_api_AuditLog_Create_POST = (): RequestOptions<
    auditing_v1_paths["/api/AuditLog/Create"]["post"]
  > => {
    return {
      body: {},
    };
  };

  // EDPOO

  const edpoo_api_Klient_Get_POST = (): RequestOptions<
    edpoo_v1_paths["/api/Klient/Get"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const edpoo_api_OsobneVeci_ListElektrospotrebic_POST = (): RequestOptions<
    edpoo_v1_paths["/api/OsobneVeci/ListElektrospotrebic"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const edpoo_api_OsobneVeci_ListOsobnaVec_POST = (): RequestOptions<
    edpoo_v1_paths["/api/OsobneVeci/ListOsobnaVec"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const edpoo_api_SkladDpoh_ListSkladovaPolozka_POST = (): RequestOptions<
    edpoo_v1_paths["/api/SkladDpoh/ListSkladovaPolozka"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const edpoo_api_Stravovanie_List_POST = (): RequestOptions<
    edpoo_v1_paths["/api/Stravovanie/List"]["post"]
  > => {
    return {
      body: {},
    };
  };

  // EOO

  const eoo_api_DalsieOsobneUdaje_BlizkaOsobaPridaj_POST = (): RequestOptions<
    eoo_v1_paths["/api/DalsieOsobneUdaje/BlizkaOsobaPridaj"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam_POST =
    (): RequestOptions<
      eoo_v1_paths["/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const eoo_api_DalsieOsobneUdaje_ListPobytKlienta_POST = (): RequestOptions<
    eoo_v1_paths["/api/DalsieOsobneUdaje/ListPobytKlienta"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_DalsieOsobneUdaje_VazbyATrestyKlienta_POST = (): RequestOptions<
    eoo_v1_paths["/api/DalsieOsobneUdaje/VazbyATrestyKlienta"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_FyzickaOsoba_CreateFyzickaOsoba_POST = (): RequestOptions<
    eoo_v1_paths["/api/FyzickaOsoba/CreateFyzickaOsoba"]["post"]
  > => {
    return {
      body: {
        datumNarodenia: "",
        meno: "",
        priezvisko: "",
        pohlavieId: 0,
      },
    };
  };

  const eoo_api_FyzickaOsoba_CreateKontakt_POST = (): RequestOptions<
    eoo_v1_paths["/api/FyzickaOsoba/CreateKontakt"]["post"]
  > => {
    return {
      body: {
        typId: 0,
      },
    };
  };

  const eoo_api_FyzickaOsoba_ListAdresaOsoba_POST = (): RequestOptions<
    eoo_v1_paths["/api/FyzickaOsoba/ListAdresaOsoba"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_FyzickaOsoba_ListKontakt_POST = (): RequestOptions<
    eoo_v1_paths["/api/FyzickaOsoba/ListKontakt"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_FyzickaOsoba_UpdateFyzickaOsoba_POST = (): RequestOptions<
    eoo_v1_paths["/api/FyzickaOsoba/UpdateFyzickaOsoba"]["post"]
  > => {
    return {
      body: {
        datumNarodenia: "",
        meno: "",
        priezvisko: "",
        pohlavieId: 0,
      },
    };
  };

  const eoo_api_FyzickaOsoba_UpdateKontakt_POST = (): RequestOptions<
    eoo_v1_paths["/api/FyzickaOsoba/UpdateKontakt"]["post"]
  > => {
    return {
      body: {
        typId: 0,
      },
    };
  };

  const eoo_api_Klient_List_POST = (): RequestOptions<
    eoo_v1_paths["/api/Klient/List"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_Klient_ListRodinnyStavKlientaData_POST = (): RequestOptions<
    eoo_v1_paths["/api/Klient/ListRodinnyStavKlientaData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_Klient_ListTrestneKonanieData_POST = (): RequestOptions<
    eoo_v1_paths["/api/Klient/ListTrestneKonanieData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_Klient_ListNepravoplatnyTrestOosData_POST = (): RequestOptions<
    eoo_v1_paths["/api/Klient/ListNepravoplatnyTrestOosData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_OchranneOpatrenie_ListOchranneOpatrenieData_POST =
    (): RequestOptions<
      eoo_v1_paths["/api/OchranneOpatrenie/ListOchranneOpatrenieData"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const eoo_api_PravniZastupcoviaKlienta_PravnyZastupcaKlientaList_POST =
    (): RequestOptions<
      eoo_v1_paths["/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const eoo_api_Rozhodnutie_ListRozhodnutieData_POST = (): RequestOptions<
    eoo_v1_paths["/api/Rozhodnutie/ListRozhodnutieData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_VazbyATresty_DetailTDVData_POST = (): RequestOptions<
    eoo_v1_paths["/api/VazbyATresty/DetailTDVData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_VazbyATresty_DetailTrestData_POST = (): RequestOptions<
    eoo_v1_paths["/api/VazbyATresty/DetailTrestData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_VazbyATresty_DetailVazbaData_POST = (): RequestOptions<
    eoo_v1_paths["/api/VazbyATresty/DetailVazbaData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_VazbyATresty_ListDovodovyParagrafVazbyData_POST =
    (): RequestOptions<
      eoo_v1_paths["/api/VazbyATresty/ListDovodovyParagrafVazbyData"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const eoo_api_VazbyATresty_ListTrestData_POST = (): RequestOptions<
    eoo_v1_paths["/api/VazbyATresty/ListTrestData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const eoo_api_VazbyATresty_ListVazbaData_POST = (): RequestOptions<
    eoo_v1_paths["/api/VazbyATresty/ListVazbaData"]["post"]
  > => {
    return {
      body: {},
    };
  };

  // EPVVVT

  const epvvvt_api_Baliky_dorucene_baliky_list_POST = (): RequestOptions<
    epvvvt_v1_paths["/api/Baliky/dorucene-baliky-list"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const epvvvt_api_interfaces_Epvvvt_ListAbsolvovaneTelefonickeHovory_POST =
    (): RequestOptions<
      epvvvt_v1_paths["/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const epvvvt_api_interfaces_Epvvvt_ListPoucenia_POST = (): RequestOptions<
    epvvvt_v1_paths["/api/interfaces/Epvvvt/ListPoucenia"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const epvvvt_api_interfaces_Epvvvt_ListSocialnaDiagnostikaOdsudeneho_POST =
    (): RequestOptions<
      epvvvt_v1_paths["/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const epvvvt_api_interfaces_Epvvvt_ListZoznamAbsolvovanychVolnocasovychAktivitKruzky_POST =
    (): RequestOptions<
      epvvvt_v1_paths["/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const epvvvt_api_interfaces_Epvvvt_ListZoznamAbsolvovanychVolnocasovychAktivitOstatne_POST =
    (): RequestOptions<
      epvvvt_v1_paths["/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const epvvvt_api_interfaces_Epvvvt_ListZoznamAbsolvovanychVychovnoOsvetovychAktivit_POST =
    (): RequestOptions<
      epvvvt_v1_paths["/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const epvvvt_api_interfaces_Epvvvt_ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania_POST =
    (): RequestOptions<
      epvvvt_v1_paths["/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const epvvvt_api_interfaces_Epvvvt_ListZoznamZrealizovanychNavstev_POST =
    (): RequestOptions<
      epvvvt_v1_paths["/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const epvvvt_api_stitkovnicaklientov_ListVsetciKlienti_POST =
    (): RequestOptions<
      epvvvt_v1_paths["/api/stitkovnicaklientov/ListVsetciKlienti"]["post"]
    > => {
      return {
        body: {},
      };
    };

  // ER

  const er_api_interfaces_Er_ListZoznamDisciplinarnychOdmienKlienta_POST =
    (): RequestOptions<
      er_v1_paths["/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const er_api_interfaces_Er_ListZoznamDisciplinarnychTrestovKlienta_POST =
    (): RequestOptions<
      er_v1_paths["/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const er_api_interfaces_Er_ListZoznamInychRozhodnutiOKlientovi_POST =
    (): RequestOptions<
      er_v1_paths["/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi"]["post"]
    > => {
      return {
        body: {},
      };
    };

  // EZOO

  const ezoo_api_Integracie_ListKlientNaPracovisku_POST = (): RequestOptions<
    ezoo_v1_paths["/api/Integracie/ListKlientNaPracovisku"]["post"]
  > => {
    return {
      body: {},
    };
  };

  // FEOO

  const feoo_api_Blokacia_List_POST = (): RequestOptions<
    feoo_v1_paths["/api/Blokacia/List"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_Codelist_ListBlokaciaTyp_POST = (): RequestOptions<
    feoo_v1_paths["/api/Codelist/ListBlokaciaTyp"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_Codelist_ListTypPohybu_POST = (): RequestOptions<
    feoo_v1_paths["/api/Codelist/ListTypPohybu"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_Codelist_ListTypVeritela_POST = (): RequestOptions<
    feoo_v1_paths["/api/Codelist/ListTypVeritela"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_integracie_Kiosk_ListCeniny_POST = (): RequestOptions<
    feoo_v1_paths["/api/integracie/Kiosk/ListCeniny"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_integracie_Kiosk_ListCudziaMena_POST = (): RequestOptions<
    feoo_v1_paths["/api/integracie/Kiosk/ListCudziaMena"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_integracie_Kiosk_ListStavKontaAKreditu_POST =
    (): RequestOptions<
      feoo_v1_paths["/api/integracie/Kiosk/ListStavKontaAKreditu"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const feoo_api_integracie_Kiosk_ListVyplatnaPaska_POST = (): RequestOptions<
    feoo_v1_paths["/api/integracie/Kiosk/ListVyplatnaPaska"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_Pohladavka_List_POST = (): RequestOptions<
    feoo_v1_paths["/api/Pohladavka/List"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_Pohladavka_ListSplatkyPohladavky_POST = (): RequestOptions<
    feoo_v1_paths["/api/Pohladavka/ListSplatkyPohladavky"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_PohybyNaKonte_List_POST = (): RequestOptions<
    feoo_v1_paths["/api/PohybyNaKonte/List"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_VyzivovaciaPovinnost_List_POST = (): RequestOptions<
    feoo_v1_paths["/api/VyzivovaciaPovinnost/List"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const feoo_api_VyzivovaciaPovinnost_PrehladSplatokVyzivovacichPovinnosti_POST =
    (): RequestOptions<
      feoo_v1_paths["/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti"]["post"]
    > => {
      return {
        body: {},
      };
    };

  // HASURA

  const hasura_api_rest_get_kiosk_notifikacie_o_suboroch_POST =
    (): RequestOptions<
      hasura_v1_paths["/api/rest/get-kiosk-notifikacie-o-suboroch"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const hasura_api_rest_insert_kiosk_notifikacie_o_suboroch_POST =
    (): RequestOptions<
      hasura_v1_paths["/api/rest/insert-kiosk-notifikacie-o-suboroch"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const hasura_api_rest_v1_kiosk_ziadost_create_id_PUT = (): RequestOptions<
    hasura_v1_paths["/api/rest/v1/kiosk-ziadost-create/{id}"]["put"]
  > => {
    return {
      params: {
        path: {
          id: "id",
        },
      },
    };
  };

  // SHARE_INT

  const share_int_api_interfaces_Cis_ListEpvvvtCisTypNavstevyA_POST =
    (): RequestOptions<
      share_int_v1_paths["/api/interfaces/Cis/ListEpvvvtCisTypNavstevyA"]["post"]
    > => {
      return {
        body: {},
      };
    };

  // SZOO

  const szoo_api_interfaces_Szoo_ListZoznamZiadostiKlienta_POST =
    (): RequestOptions<
      szoo_v1_paths["/api/interfaces/Szoo/ListZoznamZiadostiKlienta"]["post"]
    > => {
      return {
        body: {},
      };
    };

  const szoo_api_Ziadosti_Create_POST = (): RequestOptions<
    szoo_v1_paths["/api/Ziadosti/Create"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const szoo_api_Ziadosti_SpatVzatie_POST = (): RequestOptions<
    szoo_v1_paths["/api/Ziadosti/SpatVzatie"]["post"]
  > => {
    return {
      body: {},
    };
  };

  // ZVJS_MP

  const zvjs_mp_bezpecny_internet_list_POST = (): RequestOptions<
    zvjs_mp_v1_paths["/bezpecny-internet/list"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const zvjs_mp_jedalnyListok_list_POST = (): RequestOptions<
    zvjs_mp_v1_paths["/jedalnyListok/list"]["post"]
  > => {
    return {
      body: {},
    };
  };

  const zvjs_mp_rezerevacia_POST = (): RequestOptions<
    rezervacia_knih_openapi_v1_paths["/rezervacia"]["post"]
  > => {
    return {
      body: {
        klientId: "",
        ustavId: 0,
        datumVytvorenia: new Date().toISOString(),
        nazovKniha: "",
        nazovAutor: "",
        tematickeZaradenie: "",
      },
    };
  };

  const zvjs_mp_rezerevacia_list_POST = (): RequestOptions<
    rezervacia_knih_openapi_v1_paths["/rezervacia/list"]["post"]
  > => {
    return {
      body: {
        klientId: "",
        ustavId: 0,
      },
    };
  };

  const zvjs_mp_rezerevacia_id_odoslat_POST = (): RequestOptions<
    rezervacia_knih_openapi_v1_paths["/rezervacia/{id}/odoslat"]["post"]
  > => {
    return {
      params: {
        path: {
          id: "",
        },
      },
      body: {
        datumOdoslania: new Date().toISOString(),
      },
    };
  };

  return {
    // AUDITING
    "/request_options/auditing/api/AuditLog/Create":
      auditing_api_AuditLog_Create_POST(),

    // EDPOO

    "/request_options/edpoo/api/Klient/Get": edpoo_api_Klient_Get_POST(),
    "/request_options/edpoo/api/OsobneVeci/ListElektrospotrebic":
      edpoo_api_OsobneVeci_ListElektrospotrebic_POST(),
    "/request_options/edpoo/api/OsobneVeci/ListOsobnaVec":
      edpoo_api_OsobneVeci_ListOsobnaVec_POST(),
    "/request_options/edpoo/api/SkladDpoh/ListSkladovaPolozka":
      edpoo_api_SkladDpoh_ListSkladovaPolozka_POST(),
    "/request_options/edpoo/api/Stravovanie/List":
      edpoo_api_Stravovanie_List_POST(),

    // EOO

    "/request_options/eoo/api/DalsieOsobneUdaje/BlizkaOsobaPridaj":
      eoo_api_DalsieOsobneUdaje_BlizkaOsobaPridaj_POST(),
    "/request_options/eoo/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam":
      eoo_api_DalsieOsobneUdaje_BlizkeOsobyKlientaZoznam_POST(),
    "/request_options/eoo/api/DalsieOsobneUdaje/ListPobytKlienta":
      eoo_api_DalsieOsobneUdaje_ListPobytKlienta_POST(),
    "/request_options/eoo/api/DalsieOsobneUdaje/VazbyATrestyKlienta":
      eoo_api_DalsieOsobneUdaje_VazbyATrestyKlienta_POST(),
    "/request_options/eoo/api/FyzickaOsoba/CreateFyzickaOsoba":
      eoo_api_FyzickaOsoba_CreateFyzickaOsoba_POST(),
    "/request_options/eoo/api/FyzickaOsoba/CreateKontakt":
      eoo_api_FyzickaOsoba_CreateKontakt_POST(),
    "/request_options/eoo/api/FyzickaOsoba/ListAdresaOsoba":
      eoo_api_FyzickaOsoba_ListAdresaOsoba_POST(),
    "/request_options/eoo/api/FyzickaOsoba/ListKontakt":
      eoo_api_FyzickaOsoba_ListKontakt_POST(),
    "/request_options/eoo/api/FyzickaOsoba/UpdateFyzickaOsoba":
      eoo_api_FyzickaOsoba_UpdateFyzickaOsoba_POST(),
    "/request_options/eoo/api/FyzickaOsoba/UpdateKontakt":
      eoo_api_FyzickaOsoba_UpdateKontakt_POST(),
    "/request_options/eoo/api/Klient/List": eoo_api_Klient_List_POST(),
    "/request_options/eoo/api/Klient/ListRodinnyStavKlientaData":
      eoo_api_Klient_ListRodinnyStavKlientaData_POST(),
    "/request_options/eoo/api/Klient/ListTrestneKonanieData":
      eoo_api_Klient_ListTrestneKonanieData_POST(),
    "/request_options/eoo/api/Klient/ListNepravoplatnyTrestOosData":
      eoo_api_Klient_ListNepravoplatnyTrestOosData_POST(),
    "/request_options/eoo/api/OchranneOpatrenie/ListOchranneOpatrenieData":
      eoo_api_OchranneOpatrenie_ListOchranneOpatrenieData_POST(),
    "/request_options/eoo/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList":
      eoo_api_PravniZastupcoviaKlienta_PravnyZastupcaKlientaList_POST(),
    "/request_options/eoo/api/Rozhodnutie/ListRozhodnutieData":
      eoo_api_Rozhodnutie_ListRozhodnutieData_POST(),
    "/request_options/eoo/api/VazbyATresty/DetailTDVData":
      eoo_api_VazbyATresty_DetailTDVData_POST(),
    "/request_options/eoo/api/VazbyATresty/DetailTrestData":
      eoo_api_VazbyATresty_DetailTrestData_POST(),
    "/request_options/eoo/api/VazbyATresty/DetailVazbaData":
      eoo_api_VazbyATresty_DetailVazbaData_POST(),
    "/request_options/eoo/api/VazbyATresty/ListDovodovyParagrafVazbyData":
      eoo_api_VazbyATresty_ListDovodovyParagrafVazbyData_POST(),
    "/request_options/eoo/api/VazbyATresty/ListTrestData":
      eoo_api_VazbyATresty_ListTrestData_POST(),
    "/request_options/eoo/api/VazbyATresty/ListVazbaData":
      eoo_api_VazbyATresty_ListVazbaData_POST(),

    // EPVVVT
    "/request_options/epvvvt/api/Baliky/dorucene-baliky-list":
      epvvvt_api_Baliky_dorucene_baliky_list_POST(),
    "/request_options/epvvvt/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory":
      epvvvt_api_interfaces_Epvvvt_ListAbsolvovaneTelefonickeHovory_POST(),
    "/request_options/epvvvt/api/interfaces/Epvvvt/ListPoucenia":
      epvvvt_api_interfaces_Epvvvt_ListPoucenia_POST(),
    "/request_options/epvvvt/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho":
      epvvvt_api_interfaces_Epvvvt_ListSocialnaDiagnostikaOdsudeneho_POST(),
    "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky":
      epvvvt_api_interfaces_Epvvvt_ListZoznamAbsolvovanychVolnocasovychAktivitKruzky_POST(),
    "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne":
      epvvvt_api_interfaces_Epvvvt_ListZoznamAbsolvovanychVolnocasovychAktivitOstatne_POST(),
    "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit":
      epvvvt_api_interfaces_Epvvvt_ListZoznamAbsolvovanychVychovnoOsvetovychAktivit_POST(),
    "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania":
      epvvvt_api_interfaces_Epvvvt_ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania_POST(),
    "/request_options/epvvvt/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev":
      epvvvt_api_interfaces_Epvvvt_ListZoznamZrealizovanychNavstev_POST(),
    "/request_options/epvvvt/api/stitkovnicaklientov/ListVsetciKlienti":
      epvvvt_api_stitkovnicaklientov_ListVsetciKlienti_POST(),

    // ER

    "/request_options/er/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta":
      er_api_interfaces_Er_ListZoznamDisciplinarnychOdmienKlienta_POST(),
    "/request_options/er/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta":
      er_api_interfaces_Er_ListZoznamDisciplinarnychTrestovKlienta_POST(),
    "/request_options/er/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi":
      er_api_interfaces_Er_ListZoznamInychRozhodnutiOKlientovi_POST(),

    // EZOO

    "/request_options/ezoo/api/Integracie/ListKlientNaPracovisku":
      ezoo_api_Integracie_ListKlientNaPracovisku_POST(),

    // FEOO

    "/request_options/feoo/api/Blokacia/List": feoo_api_Blokacia_List_POST(),
    "/request_options/feoo/api/Codelist/ListBlokaciaTyp":
      feoo_api_Codelist_ListBlokaciaTyp_POST(),
    "/request_options/feoo/api/Codelist/ListTypPohybu":
      feoo_api_Codelist_ListTypPohybu_POST(),
    "/request_options/feoo/api/Codelist/ListTypVeritela":
      feoo_api_Codelist_ListTypVeritela_POST(),
    "/request_options/feoo/api/integracie/Kiosk/ListCeniny":
      feoo_api_integracie_Kiosk_ListCeniny_POST(),
    "/request_options/feoo/api/integracie/Kiosk/ListCudziaMena":
      feoo_api_integracie_Kiosk_ListCudziaMena_POST(),
    "/request_options/feoo/api/integracie/Kiosk/ListStavKontaAKreditu":
      feoo_api_integracie_Kiosk_ListStavKontaAKreditu_POST(),
    "/request_options/feoo/api/integracie/Kiosk/ListVyplatnaPaska":
      feoo_api_integracie_Kiosk_ListVyplatnaPaska_POST(),
    "/request_options/feoo/api/Pohladavka/List":
      feoo_api_Pohladavka_List_POST(),
    "/request_options/feoo/api/Pohladavka/ListSplatkyPohladavky":
      feoo_api_Pohladavka_ListSplatkyPohladavky_POST(),
    "/request_options/feoo/api/PohybyNaKonte/List":
      feoo_api_PohybyNaKonte_List_POST(),
    "/request_options/feoo/api/VyzivovaciaPovinnost/List":
      feoo_api_VyzivovaciaPovinnost_List_POST(),
    "/request_options/feoo/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti":
      feoo_api_VyzivovaciaPovinnost_PrehladSplatokVyzivovacichPovinnosti_POST(),

    // HASURA

    "/request_options/hasura/api/rest/get-kiosk-notifikacie-o-suboroch":
      hasura_api_rest_get_kiosk_notifikacie_o_suboroch_POST(),
    "/request_options/hasura/api/rest/insert-kiosk-notifikacie-o-suboroch":
      hasura_api_rest_insert_kiosk_notifikacie_o_suboroch_POST(),
    "/request_options/hasura/api/rest/v1/kiosk-ziadost-create/{id}":
      hasura_api_rest_v1_kiosk_ziadost_create_id_PUT(),

    // SHARE_INT
    "/request_options/share_int/api/interfaces/Cis/ListEpvvvtCisTypNavstevyA":
      share_int_api_interfaces_Cis_ListEpvvvtCisTypNavstevyA_POST(),

    // SZOO
    "/request_options/szoo/api/interfaces/Szoo/ListZoznamZiadostiKlienta":
      szoo_api_interfaces_Szoo_ListZoznamZiadostiKlienta_POST(),
    "/request_options/szoo/api/Ziadosti/Create":
      szoo_api_Ziadosti_Create_POST(),
    "/request_options/szoo/api/Ziadosti/SpatVzatie":
      szoo_api_Ziadosti_SpatVzatie_POST(),

    // ZVJS_MP
    "/request_options/zvjs_mp/bezpecny-internet/list":
      zvjs_mp_bezpecny_internet_list_POST(),
    "/request_options/zvjs_mp/jedalnyListok/list":
      zvjs_mp_jedalnyListok_list_POST(),
    "/request_options/zvjs_mp/rezervacia": zvjs_mp_rezerevacia_POST(),
    "/request_options/zvjs_mp/rezervacia/list": zvjs_mp_rezerevacia_list_POST(),
    "/request_options/zvjs_mp/rezervacia/id/odoslat":
      zvjs_mp_rezerevacia_id_odoslat_POST(),
  };
};

export type DataRequestOptionsType<
  T extends keyof ReturnType<typeof internalDataRequestOptionsTypes>
> = ReturnType<typeof internalDataRequestOptionsTypes>[T];

/**
 * Helper medhod to retrieve APIs result types
 * DON'T CALL THIS METHOD
 */
const internalDataResultTypes = async () => {
  const {
    AUDITING_Post,
    // CIS_Get,
    // CIS_Post,
    EDPOO_Get,
    EDPOO_Post,
    EOO_Get,
    EOO_Post,
    EPVVVT_Get,
    EPVVVT_Post,
    ER_Post,
    // EZOO_Get,
    EZOO_Post,
    FEOO_Get,
    FEOO_Post,
    HASURA_Get,
    HASURA_Post,
    HASURA_Put,
    // REZERVACIA_KNIH_Get,
    REZERVACIA_KNIH_Post,
    // SHARE_INT_Get,
    SHARE_INT_Post,
    // SIDKOO_Get,
    SIDKOO_Post,
    SZOO_Get,
    SZOO_Post,
    // SZOO_Local_Get,
    // SZOO_Local_Post,
    // ZVJS_MP_Get,
    ZVJS_MP_Post,
  } = await API_Clients();

  return {
    // AUDITING
    "/api/AuditLog/Create": await AUDITING_Post("/api/AuditLog/Create"),

    // EDPOO
    "/edpoo/api/Ciselnik/StavVeci": await EDPOO_Get("/api/Ciselnik/StavVeci"),
    "/edpoo/api/Klient/Get": await EDPOO_Post("/api/Klient/Get"),
    "/edpoo/api/OsobneVeci/ListElektrospotrebic": await EDPOO_Post(
      "/api/OsobneVeci/ListElektrospotrebic"
    ),
    "/edpoo/api/OsobneVeci/ListOsobnaVec": await EDPOO_Post(
      "/api/OsobneVeci/ListOsobnaVec"
    ),
    "/edpoo/api/SkladDpoh/ListSkladovaPolozka": await EDPOO_Post(
      "/api/SkladDpoh/ListSkladovaPolozka"
    ),
    "/edpoo/api/Stravovanie/List": await EDPOO_Post("/api/Stravovanie/List"),

    // EOO
    "/eoo/api/Ciselnik/DruhAdresy": await EOO_Get("/api/Ciselnik/DruhAdresy"),
    "/eoo/api/Ciselnik/StupenDosiahnutehoVzdelania": await EOO_Get(
      "/api/Ciselnik/StupenDosiahnutehoVzdelania"
    ),
    "/eoo/api/DalsieOsobneUdaje/BlizkaOsobaPridaj": await EOO_Post(
      "/api/DalsieOsobneUdaje/BlizkaOsobaPridaj"
    ),
    "/eoo/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam": await EOO_Post(
      "/api/Integracie/BlizkeOsobyKlientaZoznam"
    ),
    "/eoo/api/DalsieOsobneUdaje/ListPobytKlienta": await EOO_Post(
      "/api/DalsieOsobneUdaje/ListPobytKlienta"
    ),
    "/eoo/api/DalsieOsobneUdaje/VazbyATrestyKlienta": await EOO_Post(
      "/api/DalsieOsobneUdaje/VazbyATrestyKlienta"
    ),
    "/eoo/api/FyzickaOsoba/CreateFyzickaOsoba": await EOO_Post(
      "/api/FyzickaOsoba/CreateFyzickaOsoba"
    ),
    "/eoo/api/FyzickaOsoba/CreateKontakt": await EOO_Post(
      "/api/FyzickaOsoba/CreateKontakt"
    ),
    "/eoo/api/FyzickaOsoba/DetailData": await EOO_Get(
      "/api/FyzickaOsoba/DetailData"
    ),
    "/eoo/api/FyzickaOsoba/ListAdresaOsoba": await EOO_Post(
      "/api/FyzickaOsoba/ListAdresaOsoba"
    ),
    "/eoo/api/FyzickaOsoba/ListKontakt": await EOO_Post(
      "/api/FyzickaOsoba/ListKontakt"
    ),
    "/eoo/api/FyzickaOsoba/UpdateFyzickaOsoba": await EOO_Post(
      "/api/FyzickaOsoba/UpdateFyzickaOsoba"
    ),
    "/eoo/api/FyzickaOsoba/UpdateKontakt": await EOO_Post(
      "/api/FyzickaOsoba/UpdateKontakt"
    ),
    "/eoo/api/Klient/DetailData": await EOO_Get("/api/Klient/DetailData"),
    "/eoo/api/Klient/DetailZakladneUdajeKlientaData": await EOO_Get(
      "/api/Klient/DetailZakladneUdajeKlientaData"
    ),
    "/eoo/api/Klient/List": await EOO_Post("/api/Klient/List"),
    "/eoo/api/Klient/ListRodinnyStavKlientaData": await EOO_Post(
      "/api/Klient/ListRodinnyStavKlientaData"
    ),
    "/eoo/api/Klient/ListTrestneKonanieData": await EOO_Post(
      "/api/Klient/ListTrestneKonanieData"
    ),
    "/eoo/api/Klient/ListNepravoplatnyTrestOosData": await EOO_Post(
      "/api/Klient/ListNepravoplatnyTrestOosData"
    ),
    "/eoo/api/Klient/XDetailOstatneUdajeData": await EOO_Get(
      "/api/Klient/XDetailOstatneUdajeData"
    ),
    "/eoo/api/OchranneOpatrenie/ListOchranneOpatrenieData": await EOO_Post(
      "/api/OchranneOpatrenie/ListOchranneOpatrenieData"
    ),
    "/eoo/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList":
      await EOO_Post("/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList"),
    "/eoo/api/Rozhodnutie/ListRozhodnutieData": await EOO_Post(
      "/api/Rozhodnutie/ListRozhodnutieData"
    ),
    "/eoo/api/VazbyATresty/DetailTDVData": await EOO_Post(
      "/api/VazbyATresty/DetailTDVData"
    ),
    "/eoo/api/VazbyATresty/DetailTrestData": await EOO_Post(
      "/api/VazbyATresty/DetailTrestData"
    ),
    "/eoo/api/VazbyATresty/DetailVazbaData": await EOO_Post(
      "/api/VazbyATresty/DetailVazbaData"
    ),
    "/eoo/api/VazbyATresty/ListDovodovyParagrafVazbyData": await EOO_Post(
      "/api/VazbyATresty/ListDovodovyParagrafVazbyData"
    ),
    "/eoo/api/VazbyATresty/ListTrestData": await EOO_Post(
      "/api/VazbyATresty/ListTrestData"
    ),
    "/eoo/api/VazbyATresty/ListVazbaData": await EOO_Post(
      "/api/VazbyATresty/ListVazbaData"
    ),

    // EPVVVT

    "/epvvvt/api/Baliky/dorucene-baliky-list": await EPVVVT_Post(
      "/api/Baliky/dorucene-baliky-list"
    ),
    "/epvvvt/api/evidencne-karty/{klientId}/odoslana-korespondencia":
      await EPVVVT_Get(
        "/api/evidencne-karty/{klientId}/odoslana-korespondencia",
        {
          params: {
            path: {
              klientId: "1",
            },
          },
        }
      ),
    "/epvvvt/api/evidencne-karty/{klientId}/prijata-korespondencia":
      await EPVVVT_Get(
        "/api/evidencne-karty/{klientId}/prijata-korespondencia",
        {
          params: {
            path: {
              klientId: "1",
            },
          },
        }
      ),
    "/epvvvt/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory":
      await EPVVVT_Post(
        "/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory"
      ),
    "/epvvvt/api/interfaces/Epvvvt/ListPoucenia": await EPVVVT_Post(
      "/api/interfaces/Epvvvt/ListPoucenia"
    ),
    "/epvvvt/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho":
      await EPVVVT_Post(
        "/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho"
      ),
    "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky":
      await EPVVVT_Post(
        "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky"
      ),
    "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne":
      await EPVVVT_Post(
        "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne"
      ),
    "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit":
      await EPVVVT_Post(
        "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit"
      ),
    "/epvvvt/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania":
      await EPVVVT_Post(
        "/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania"
      ),
    "/epvvvt/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev":
      EPVVVT_Post("/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev"),
    "/epvvvt/api/Navstevy/navstevy/{id}": await EPVVVT_Get(
      "/api/Navstevy/navstevy/{id}",
      {
        params: {
          path: {
            id: 0,
          },
        },
      }
    ),
    "/epvvvt/api/stitkovnicaklientov/ListVsetciKlienti": await EPVVVT_Post(
      "/api/stitkovnicaklientov/ListVsetciKlienti"
    ),

    // ER

    "/er/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta":
      await ER_Post(
        "/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta"
      ),
    "/er/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta":
      await ER_Post(
        "/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta"
      ),
    "/er/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi": await ER_Post(
      "/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi"
    ),

    // EZOO

    "/ezoo/api/Integracie/ListKlientNaPracovisku": EZOO_Post(
      "/api/Integracie/ListKlientNaPracovisku"
    ),

    // FEOO
    "/feoo/api/Blokacia/List": await FEOO_Post("/api/Blokacia/List"),
    "/feoo/api/Codelist/ListBlokaciaTyp": await FEOO_Post(
      "/api/Codelist/ListBlokaciaTyp"
    ),
    "/feoo/api/Codelist/ListTypPohybu": await FEOO_Post(
      "/api/Codelist/ListTypPohybu"
    ),
    "/feoo/api/Codelist/ListTypVeritela": await FEOO_Post(
      "/api/Codelist/ListTypVeritela"
    ),
    "/feoo/api/integracie/Kiosk/GetZivotneMinimum": await FEOO_Get(
      "/api/integracie/Kiosk/GetZivotneMinimum"
    ),
    "/feoo/api/integracie/Kiosk/ListCeniny": await FEOO_Post(
      "/api/integracie/Kiosk/ListCeniny"
    ),
    "/feoo/api/integracie/Kiosk/ListCudziaMena": await FEOO_Post(
      "/api/integracie/Kiosk/ListCudziaMena"
    ),
    "/feoo/api/integracie/Kiosk/ListStavKontaAKreditu": await FEOO_Post(
      "/api/integracie/Kiosk/ListStavKontaAKreditu"
    ),
    "/feoo/api/integracie/Kiosk/ListVyplatnaPaska": await FEOO_Post(
      "/api/integracie/Kiosk/ListVyplatnaPaska"
    ),
    "/feoo/api/Klient/Get": await FEOO_Get("/api/Klient/Get"),
    "/feoo/api/KlientUni/Get": await FEOO_Get("/api/KlientUni/Get"),
    "/feoo/api/Pohladavka/List": await FEOO_Post("/api/Pohladavka/List"),
    "/feoo/api/Pohladavka/ListSplatkyPohladavky": await FEOO_Post(
      "/api/Pohladavka/ListSplatkyPohladavky"
    ),
    "/feoo/api/PohybyNaKonte/List": await FEOO_Post("/api/PohybyNaKonte/List"),
    "/feoo/api/VyzivovaciaPovinnost/List": await FEOO_Post(
      "/api/VyzivovaciaPovinnost/List"
    ),
    "/feoo/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti":
      await FEOO_Post(
        "/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti"
      ),

    // HASURA
    "/hasura/api/rest/getcisprevadzky": await HASURA_Get(
      "/api/rest/getcisprevadzky"
    ),
    "/hasura/api/rest/get-kiosk-notifikacie-o-suboroch": await HASURA_Post(
      "/api/rest/get-kiosk-notifikacie-o-suboroch"
    ),
    "/hasura/api/rest/insert-kiosk-notifikacie-o-suboroch": await HASURA_Post(
      "/api/rest/insert-kiosk-notifikacie-o-suboroch"
    ),
    "/hasura/api/rest/v1/kiosk-ziadost-by-id/{id}": await HASURA_Get(
      "/api/rest/v1/kiosk-ziadost-by-id/{id}",
      {
        params: {
          path: {
            id: "id",
          },
        },
      }
    ),
    "/hasura/api/rest/v1/kiosk-ziadost-create/{id}": await HASURA_Put(
      "/api/rest/v1/kiosk-ziadost-create/{id}",
      {
        params: {
          path: {
            id: "id",
          },
        },
      }
    ),
    "/hasura/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-a":
      await HASURA_Get("/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-a"),
    "/hasura/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-norma-a":
      await HASURA_Get(
        "/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-norma-a"
      ),

    // SHARE_INT
    "/share_int/api/interfaces/Cis/ListEpvvvtCisTypNavstevyA":
      await SHARE_INT_Post("/api/interfaces/Cis/ListEpvvvtCisTypNavstevyA"),

    // SIDKOO
    "/sidkoo/api/IdKarta/ListIdKarta": await SIDKOO_Post(
      "/api/IdKarta/ListIdKarta"
    ),

    // SZOO
    "/szoo/api/interfaces/Szoo/ListZoznamZiadostiKlienta": await SZOO_Post(
      "/api/interfaces/Szoo/ListZoznamZiadostiKlienta"
    ),
    "/szoo/api/Ziadosti/Create": await SZOO_Post("/api/Ziadosti/Create"),
    "/szoo/api/Ziadosti/Get": await SZOO_Get("/api/Ziadosti/Get"),
    "/szoo/api/Ziadosti/GetOdpovedPreKlienta": await SZOO_Get(
      "/api/Ziadosti/GetOdpovedPreKlienta"
    ),
    "/szoo/api/Ziadosti/SpatVzatie": await SZOO_Post(
      "/api/Ziadosti/SpatVzatie"
    ),
    // ZVJS_MP
    // "/zvjs_mp/administracia-tabletu": await ZVJS_MP_Post(
    //   "/administracia-tabletu"
    // ),
    "/zvjs_mp/bezpecny-internet/list": await ZVJS_MP_Post(
      "/bezpecny-internet/list"
    ),
    "/zvjs_mp/jedalnyListok/list": await ZVJS_MP_Post("/jedalnyListok/list", {
      body: {},
    }),
    "/zvjs_mp/rezervacia": await REZERVACIA_KNIH_Post("/rezervacia", {
      body: {
        klientId: "",
        ustavId: 0,
        datumVytvorenia: new Date().toISOString(),
        nazovKniha: "",
        nazovAutor: "",
        tematickeZaradenie: "",
      },
    }),
    "/zvjs_mp/rezervacia/list": await REZERVACIA_KNIH_Post("/rezervacia/list"),
    "/zvjs_mp/rezervacia/id/odoslat": await REZERVACIA_KNIH_Post(
      "/rezervacia/{id}/odoslat",
      {
        params: {
          path: {
            id: "",
          },
        },
        body: {
          datumOdoslania: new Date().toISOString(),
        },
      }
    ),
  };
};

export type DataResultType<
  T extends keyof Awaited<ReturnType<typeof internalDataResultTypes>>
> = Awaited<ReturnType<typeof internalDataResultTypes>>[T];

export const DataArray: Array<
  keyof Awaited<ReturnType<typeof internalDataResultTypes>>
> = [
  "/edpoo/api/Klient/Get",
  "/edpoo/api/Ciselnik/StavVeci",
  "/edpoo/api/OsobneVeci/ListElektrospotrebic",
  "/edpoo/api/OsobneVeci/ListOsobnaVec",
  "/edpoo/api/SkladDpoh/ListSkladovaPolozka",
  "/edpoo/api/Stravovanie/List",
  "/eoo/api/Ciselnik/DruhAdresy",
  "/eoo/api/Ciselnik/StupenDosiahnutehoVzdelania",
  // "/eoo/api/DalsieOsobneUdaje/BlizkaOsobaPridaj", THIS IS DEFINED IN OUTGOING DATA
  "/eoo/api/DalsieOsobneUdaje/BlizkeOsobyKlientaZoznam",
  "/eoo/api/DalsieOsobneUdaje/ListPobytKlienta",
  "/eoo/api/DalsieOsobneUdaje/VazbyATrestyKlienta",
  // "/eoo/api/FyzickaOsoba/CreateFyzickaOsoba", THIS IS DEFINED IN OUTGOING DATA
  // "/eoo/api/FyzickaOsoba/CreateKontakt", THIS IS DEFINED IN OUTGOING DATA
  "/eoo/api/FyzickaOsoba/DetailData",
  "/eoo/api/FyzickaOsoba/ListAdresaOsoba",
  "/eoo/api/FyzickaOsoba/ListKontakt",
  // "/eoo/api/FyzickaOsoba/UpdateFyzickaOsoba", THIS IS DEFINED IN OUTGOING DATA
  // "/eoo/api/FyzickaOsoba/UpdateKontakt", THIS IS DEFINED IN OUTGOING DATA
  "/eoo/api/Klient/DetailData",
  "/eoo/api/Klient/DetailZakladneUdajeKlientaData",
  "/eoo/api/Klient/List",
  "/eoo/api/Klient/ListRodinnyStavKlientaData",
  "/eoo/api/Klient/ListTrestneKonanieData",
  "/eoo/api/Klient/ListNepravoplatnyTrestOosData",
  "/eoo/api/Klient/XDetailOstatneUdajeData",
  "/eoo/api/OchranneOpatrenie/ListOchranneOpatrenieData",
  "/eoo/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList",
  "/eoo/api/Rozhodnutie/ListRozhodnutieData",
  "/eoo/api/VazbyATresty/DetailTDVData",
  "/eoo/api/VazbyATresty/DetailTrestData",
  "/eoo/api/VazbyATresty/DetailVazbaData",
  "/eoo/api/VazbyATresty/ListDovodovyParagrafVazbyData",
  "/eoo/api/VazbyATresty/ListTrestData",
  "/eoo/api/VazbyATresty/ListVazbaData",
  "/epvvvt/api/Baliky/dorucene-baliky-list",
  "/epvvvt/api/evidencne-karty/{klientId}/odoslana-korespondencia",
  "/epvvvt/api/evidencne-karty/{klientId}/prijata-korespondencia",
  "/epvvvt/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory",
  "/epvvvt/api/interfaces/Epvvvt/ListPoucenia",
  "/epvvvt/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania",
  "/epvvvt/api/interfaces/Epvvvt/ListZoznamZrealizovanychNavstev",
  "/epvvvt/api/Navstevy/navstevy/{id}",
  "/epvvvt/api/stitkovnicaklientov/ListVsetciKlienti",
  "/er/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta",
  "/er/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta",
  "/er/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi",
  "/ezoo/api/Integracie/ListKlientNaPracovisku",
  "/feoo/api/Blokacia/List",
  "/feoo/api/Codelist/ListBlokaciaTyp",
  "/feoo/api/Codelist/ListTypPohybu",
  "/feoo/api/Codelist/ListTypVeritela",
  "/feoo/api/integracie/Kiosk/GetZivotneMinimum",
  "/feoo/api/integracie/Kiosk/ListCeniny",
  "/feoo/api/integracie/Kiosk/ListCudziaMena",
  "/feoo/api/integracie/Kiosk/ListStavKontaAKreditu",
  "/feoo/api/integracie/Kiosk/ListVyplatnaPaska",
  "/feoo/api/Klient/Get",
  "/feoo/api/KlientUni/Get",
  "/feoo/api/Pohladavka/List",
  "/feoo/api/Pohladavka/ListSplatkyPohladavky",
  "/feoo/api/PohybyNaKonte/List",
  "/feoo/api/VyzivovaciaPovinnost/List",
  "/feoo/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti",
  "/hasura/api/rest/getcisprevadzky",
  "/hasura/api/rest/get-kiosk-notifikacie-o-suboroch",
  // "/hasura/api/rest/insert-kiosk-notifikacie-o-suboroch", THIS IS DEFINED IN OUTGOING DATA
  "/hasura/api/rest/v1/kiosk-ziadost-by-id/{id}",
  // "/hasura/api/rest/v1/kiosk-ziadost-create/{id}", THIS IS DEFINED IN OUTGOING DATA
  "/hasura/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-a",
  "/hasura/api/rest/v1/get-dalsie-potreby-osobnej-hygieny-norma-a",
  "/share_int/api/interfaces/Cis/ListEpvvvtCisTypNavstevyA",
  "/sidkoo/api/IdKarta/ListIdKarta",
  "/szoo/api/interfaces/Szoo/ListZoznamZiadostiKlienta",
  // "/szoo/api/Ziadosti/Create", THIS IS DEFINED IN OUTGOING DATA
  "/szoo/api/Ziadosti/Get",
  "/szoo/api/Ziadosti/GetOdpovedPreKlienta",
  // "/szoo/api/Ziadosti/SpatVzatie", THIS IS DEFINED IN OUTGOING DATA
  // "/zvjs_mp/administracia-tabletu", THIS IS NOT NEEDED
  "/zvjs_mp/bezpecny-internet/list",
  "/zvjs_mp/jedalnyListok/list",
  // "/zvjs_mp/rezervacia", THIS IS DEFINED IN OUTGOING DATA
  "/zvjs_mp/rezervacia/list",
  // "/zvjs_mp/rezervacia/id/odoslat", THIS IS DEFINED IN OUTGOING DATA
];

export const DataRequestOptionsArray: Array<
  keyof ReturnType<typeof internalDataRequestOptionsTypes>
> = [
  "/request_options/eoo/api/DalsieOsobneUdaje/BlizkaOsobaPridaj",
  "/request_options/eoo/api/FyzickaOsoba/CreateFyzickaOsoba",
  "/request_options/eoo/api/FyzickaOsoba/CreateKontakt",
  "/request_options/eoo/api/FyzickaOsoba/UpdateFyzickaOsoba",
  "/request_options/eoo/api/FyzickaOsoba/UpdateKontakt",
  "/request_options/hasura/api/rest/insert-kiosk-notifikacie-o-suboroch",
  "/request_options/hasura/api/rest/v1/kiosk-ziadost-create/{id}",
  "/request_options/szoo/api/Ziadosti/Create",
  "/request_options/szoo/api/Ziadosti/SpatVzatie",
  "/request_options/zvjs_mp/rezervacia",
  "/request_options/zvjs_mp/rezervacia/id/odoslat",
];
