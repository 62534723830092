import React, { useContext, useEffect, useState } from "react";
import AuthContext, {
  CheckIsLoggedOutGuardLoader,
} from "../store/context/auth-context";
import { Stack } from "@mui/material";
import LoginStep1 from "./loginSteps/LoginStep1";
import LoginStep2 from "./loginSteps/LoginStep2";
import LoginStep3 from "./loginSteps/LoginStep3";
import { defer, useLocation, useNavigate } from "react-router-dom";
import { withLoader } from "../hoc/withLoader";
import { KEY_API_KEY, KEY_SVC_URL } from "../store/context/envConfigContext";
import { dbPromise } from "../services/database";
import { API_Clients } from "../store/context/dataApi/Data";

const loadData = async () => {
  const { SIDKOO_Post } = await API_Clients();
  const data = await Promise.all([SIDKOO_Post("/api/IdKarta/ListIdKarta")]);
  return {
    listIdKariet: data[0],
  };
};

export const LoginLoader = async () => {
  // WORKAROUND START
  const database = await dbPromise;
  const svcUrlDb = await database.get("EnvironmentVariables", KEY_SVC_URL);
  const apiKeyDb = await database.get("EnvironmentVariables", KEY_API_KEY);

  if (
    !localStorage.getItem("KEY_SVC_URL") ||
    !localStorage.getItem("KEY_API_KEY") ||
    !svcUrlDb ||
    !apiKeyDb
  ) {
    console.error("LoginLOADER FIX");
    const envConfigResponse = await fetch("/env.json", {
      headers: { "X-Zvjs-Application-File": "true" },
      method: "GET",
    });
    const envConfigData = await envConfigResponse.json();

    database.put(
      "EnvironmentVariables",
      envConfigData?.svcUrl ?? "",
      KEY_SVC_URL
    );
    database.put(
      "EnvironmentVariables",
      envConfigData?.apiKey ?? "",
      KEY_API_KEY
    );

    localStorage.setItem(KEY_SVC_URL, envConfigData?.svcUrl ?? "");
    localStorage.setItem(KEY_API_KEY, envConfigData?.apiKey ?? "");
  }
  // WORKAROUND END

  const checkIsLoggedOutGuardResult = CheckIsLoggedOutGuardLoader();
  if (checkIsLoggedOutGuardResult === null) {
    return defer({
      data: loadData(),
    });
  } else {
    return checkIsLoggedOutGuardResult;
  }
};

export interface LoginProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

export type LoginLoaderDataType = Awaited<ReturnType<typeof loadData>>;

export interface LoginStepProps {
  setStep: (step: number) => void;
  data: LoginLoaderDataType;
}

const Login = (props: LoginProps) => {
  const { onLogout, isLoggedIn } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    if (location.state?.initialLoginStep) {
      console.debug("Initial login step requested");
      onLogout();
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // after user logged in, redirect him to auth page
  useEffect(() => {
    if (isLoggedIn) {
      navigate("auth/Notifikacie", {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Stack alignItems="center" padding={3} spacing={3} height={"75vh"}>
      {step === 1 && <LoginStep1 setStep={setStep} data={props.data} />}
      {step === 2 && <LoginStep2 setStep={setStep} data={props.data} />}
      {step === 3 && <LoginStep3 setStep={setStep} data={props.data} />}
    </Stack>
  );
};

export default withLoader(Login);
