import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { CisUstavZvjs_GR_Kod } from "../../../../../../../specs/countersValues";
import { getTranslationByLanguageCode } from "../../../../../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../../../../../locales/i18n";
import i18n from "i18next";
import { CiselnikListResultType } from "../../../../../../../../store/context/dataApi/CIS_Ciselnik";
import { LoaderError } from "../../../../../../../../router/LoaderError";

/**
 * Selectors
 */

const getInstitutionsQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const { tuiz, getFallbackJazyk } = useUITranslation();
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: tuiz(question.title),
      answerRequired: question.isRequired,
      options:
        (
          state.counters[
            "/api/CisUstavZvjs/List"
          ] as CiselnikListResultType<"/api/CisUstavZvjs/List">["data"]
        )?.records
          ?.filter((item) => item.kod !== CisUstavZvjs_GR_Kod && item.kod)
          .map((item) => {
            if (item.kod) {
              return {
                value: item.kod,
                text: getTranslationByLanguageCode(
                  state.counters["/api/CisUstavZvjs/List"]?.records ?? [],
                  getCiselnikJazyk(i18n.language),
                  getFallbackJazyk(),
                  item.kod,
                  "nazov"
                ),
              };
            }
            // you should never end up here because of the filter check above (compilation is the reason why error throw is here)
            throw new LoaderError();
          }) ?? [],
    };
  });

export const allSelectors = {
  getInstitutionsQuestionDisplayData,
};
