import {
  AppBar,
  Avatar,
  Box,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import LanguageSelect from "./LanguageSelect";
import AuthContext from "../store/context/auth-context";
import { ZvjsButton } from "../common";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as HomeIcon } from "../assets/icons/Other/home.svg";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import { useUITranslation } from "../store/context/translation-context";
import { API_Clients } from "../store/context/dataApi/Data";

function Header() {
  const { tui } = useUITranslation();
  const [fontSize, setFontSize] = useState<number>(
    window.innerWidth <= 920 ? 34 : 36
  );
  const [meno, setMeno] = useState<string>();
  const [priezvisko, setPriezvisko] = useState<string>();

  const { isLoggedIn, cisloKarty, klientId, onLogout } =
    useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 920) {
        setFontSize(34);
        return;
      } else if (window.innerWidth > 920) {
        setFontSize(36);
        return;
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [fontSize]);

  useEffect(() => {
    if (isLoggedIn) {
      API_Clients()
        .then((clients) => {
          clients
            .EOO_Get("/api/Klient/DetailZakladneUdajeKlientaData", {
              params: {
                query: {
                  Id:
                    Number.parseInt(localStorage.getItem("klientId") ?? "") ??
                    1,
                  UstavZvjsId:
                    Number.parseInt(
                      localStorage.getItem("klientUstavId") ?? ""
                    ) ?? 1,
                },
              },
            })
            .then((data) => {
              setMeno(
                `${data.data?.data?.meno ?? ""} ${
                  data.data?.data?.druheMeno ?? ""
                }`
              );
              setPriezvisko(data.data?.data?.priezvisko ?? "");
            });
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    } else {
      setMeno("");
      setPriezvisko("");
    }
  }, [isLoggedIn, klientId]);

  return (
    <AppBar position="fixed">
      <Toolbar color="default">
        <Stack
          height="8rem"
          width="100%"
          direction="row"
          padding={2}
          spacing={1}
          alignItems="center"
        >
          <Stack spacing={0}>
            <Stack
              direction="row"
              spacing={1}
              alignItems={isLoggedIn ? "start" : "center"}
            >
              <Link
                to={isLoggedIn ? "/auth" : "/"}
                state={!isLoggedIn ? { initialLoginStep: true } : undefined}
              >
                <HomeIcon style={{ fill: "#626A6E" }} />
              </Link>
              <Typography variant="h3" fontSize={fontSize}>
                {tui("Aplikácia Kiosk")}
              </Typography>
            </Stack>
            {isLoggedIn && (
              <Stack direction="row">
                <Grid>
                  <LanguageSelect />
                </Grid>
              </Stack>
            )}
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={3} alignItems="center">
            {isLoggedIn && (
              <>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Link to={"/auth/Notifikacie"}>
                    <Avatar
                      sx={{
                        color: "black",
                        backgroundColor: "white",
                        border: "2px solid black",
                      }}
                    >
                      <PersonIcon />
                    </Avatar>
                  </Link>
                  <Stack spacing={-1}>
                    <Typography variant="h4" color="default">
                      {`${meno} ${priezvisko}`}
                    </Typography>
                    <Typography variant="h6" color="default">
                      {cisloKarty}
                    </Typography>
                  </Stack>
                </Stack>

                <ZvjsButton
                  text={capitalize(tui("tlacidla.odhlasit"))}
                  endIcon={<ExitToAppIcon style={{ height: 36, width: 36 }} />}
                  zvjsVariant={"primaryAction"}
                  onClick={() => {
                    onLogout();
                  }}
                />
              </>
            )}
            {!isLoggedIn && (
              <Stack direction="row">
                <Grid>
                  <LanguageSelect />
                </Grid>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
