import { Grid, Typography } from "@mui/material";
import { Dashboard_SC_KIO_0200, ZvjsCard } from "../../common";
import React, { useEffect, useState } from "react";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as RequestsCategoriesIcon } from "../../assets/icons/Other/requests_categories.svg";
import { ReactComponent as HolupIcon } from "../../assets/icons/Other/holup.svg";
import { ReactComponent as MyDataIcon } from "../../assets/icons/Other/my_data.svg";
import { ReactComponent as NoticeBoardIcon } from "../../assets/icons/Other/notice_board.svg";
import { ReactComponent as SafeInternetIcon } from "../../assets/icons/Other/safe_internet.svg";
import { defer, useNavigate } from "react-router-dom";
import { withLoader } from "../../hoc/withLoader";
import { API_Clients } from "../../store/context/dataApi/Data";
import { isTablet } from "../../store/context/envConfigContext";

const loadData = async () => {
  const { EOO_Get } = await API_Clients();

  const data = await Promise.all([
    EOO_Get("/api/Klient/DetailZakladneUdajeKlientaData", {
      params: {
        query: {
          Id: Number(localStorage.getItem("klientId")),
          UstavZvjsId: Number(localStorage.getItem("klientUstavId")),
        },
      },
    }),
  ]);
  return {
    zakladneUdajeKlienta: data[0],
  };
};

export const DashboardLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface DashboardProps {
  data: Awaited<ReturnType<typeof loadData>>;
}

const Dashboard = (props: DashboardProps) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const [windowHeight, setWindowHeight] = useState<string>(
    window.innerWidth <= 920 ? "22em" : "18em"
  );
  const [smGrid, setSmGrid] = useState<number>(6);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const icon = (key: Dashboard_SC_KIO_0200): React.FunctionComponent<any> => {
    const iconMap = new Map<
      Dashboard_SC_KIO_0200,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      React.FunctionComponent<any>
    >();
    iconMap.set(Dashboard_SC_KIO_0200.Requests, RequestsCategoriesIcon);
    iconMap.set(Dashboard_SC_KIO_0200.NoticeBoard, NoticeBoardIcon);
    iconMap.set(Dashboard_SC_KIO_0200.MyData, MyDataIcon);
    iconMap.set(Dashboard_SC_KIO_0200.SafeInternet, SafeInternetIcon);
    iconMap.set(Dashboard_SC_KIO_0200.Holup, HolupIcon);
    return iconMap.get(key) ?? NoticeBoardIcon;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 920 && window.innerWidth > 770) {
        setWindowHeight("22em");
        setSmGrid(6);
        return;
      } else if (window.innerWidth <= 770) {
        setSmGrid(12);
        return;
      } else if (window.innerWidth > 920) {
        setWindowHeight("18em");
        setSmGrid(6);
        return;
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [smGrid, windowHeight]);

  const getAvailableDashboardCards = () => {
    const items = [];
    for (const [index, item] of Object.keys(Dashboard_SC_KIO_0200).entries()) {
      if (!(item === Dashboard_SC_KIO_0200.Holup && isTablet())) {
        items.push(
          <Grid key={index} item xs={12} sm={smGrid} md={smGrid} lg={6}>
            <ZvjsCard
              title={capitalize(
                item === Dashboard_SC_KIO_0200.Requests
                  ? tui("tlacidla.ziadosti")
                  : item === Dashboard_SC_KIO_0200.MyData
                  ? tui("dashboard.mojeUdaje")
                  : item === Dashboard_SC_KIO_0200.NoticeBoard
                  ? tui("dashboard.nastenka")
                  : item === Dashboard_SC_KIO_0200.SafeInternet
                  ? tui("dashboard.bezpecnyInternet")
                  : tui("dashboard.holup")
              )}
              description={
                item === Dashboard_SC_KIO_0200.Requests
                  ? tui(
                      "Podanie novej žiadanky, prehľad už podaných žiadaniek."
                    )
                  : item === Dashboard_SC_KIO_0200.MyData
                  ? tui(
                      "Moje osobné údaje, údaje o vlastných peňažných prostriedkoch a ďalšie údaje o mojej osobe. Prístup k poučeniam, údajom z konta iné."
                    )
                  : item === Dashboard_SC_KIO_0200.NoticeBoard
                  ? tui(
                      "Informácie o ústavnom poriadku, aktivitách, jednálnom listku a ďalšie informácie."
                    )
                  : item === Dashboard_SC_KIO_0200.SafeInternet
                  ? tui(
                      "Obmedzený prístup k zákonom a informáciám dostupným na webových stránkach štátnej správy."
                    )
                  : tui("Aplikácia pre prístp k projektu Šanca na návrat.")
              }
              icon={icon(
                Dashboard_SC_KIO_0200[
                  item as keyof typeof Dashboard_SC_KIO_0200
                ]
              )}
              sx={{ height: windowHeight }}
              onClick={() => {
                navigate(`${item}`);
              }}
            />
          </Grid>
        );
      }
    }
    return items;
  };

  return (
    <Grid p={5} pt={2} mb={12}>
      <Grid mb={3}>
        <div>
          <Typography variant={"h1"}>
            {capitalize(tui("dashboard.dobryDen"))}
            {", "}
            {loaderData.zakladneUdajeKlienta.data?.data?.meno}
          </Typography>
          <Typography variant={"subtitle1"} sx={{ lineHeight: 1.2 }}>
            {tui("dashboard.uvodnyClaim")}
          </Typography>
        </div>
      </Grid>
      <Typography variant={"h2"} mb={3}>
        {capitalize(tui("dashboard.ponukaSluzieb"))}
      </Typography>
      <Grid container spacing={3}>
        <>{getAvailableDashboardCards()}</>
      </Grid>
    </Grid>
  );
};

export default withLoader(Dashboard);
